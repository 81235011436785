.backarrow {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  left: 1.5rem;
  top: 0.3rem;


}

.cAwhiteback {
  width: 840px;
  height: 679px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.proimage>.profile {
  width: 99px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}


.proimage>:nth-child(3) {
  position: absolute;
  top: 45.2%;
  right: 43.3%;
  cursor: pointer;
}

.proimage>input {
  display: none;
}

.p {
  color: red !important;
  word-break: break-word;
}

.cmainCnt {
  width: 760px;
  height: 599px;
  gap: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  margin-top: -1rem;
  position: relative;
}

.errordiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.errordiv>:nth-last-child(1) {
  align-self: flex-end;
}

.cmainCnt>h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #171d1e;
}

/* wedved logo */
.cmainCnt> :nth-child(2) {
  width: 100px;
}

.khainwaladiv2 {
  width: 760px;
  height: 169px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.khainwaladiv2>p {
  color: #171D1E;
  font-size: 14px;
  font-weight: 400;
  align-self: flex-start;
}

.khainwaladiv2>p>span,
.havean>span {
  color: #00BFD3;
  cursor: pointer;
}

.userNinputs {
  display: flex;
  gap: 1rem;
}

.Epasinputs {
  display: flex;
  gap: 1rem;
}

.userNfirst {
  width: 370px;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

}

.userNfirst>img {
  width: 25px;
}

.userNfirst>input {
  width: 320px;
  height: 40px;
  border: none;
  outline: none;
  padding: 10px;
  background: transparent;
}

.epinput>img {
  width: 25px;
}


.epinput {
  width: 370px;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.epinput>input {
  width: 320px;
  height: 40px;
  border: none;
  outline: none;
  padding: 10px;
  background: transparent;
}

/* eye button */
.epinput>:nth-child(3) {
  transform: scale(1.5);
  cursor: pointer;
  color: #7F8182;
  margin-right: 7px;
  user-select: none;
}

.epinput>:nth-child(3):active {
  transform: scale(1.1);
}


.epinput>#eyeinput {
  width: 298px;
}


.createaccount_checkbox>p {
  font-size: 14px;
  color: #171D1E;
  font-weight: 400;
}

.createaccount_checkbox>p>span {
  color: #00BFD3;
  cursor: pointer;
}

.createaccount_checkbox {
  display: flex;
  align-items: center;
  gap: 10px
}

@media screen and (max-width: 1024px) {
  .cAwhiteback {
    width: 840px;
    height: 545px;
    position: relative;
  }

  .proimage>:nth-child(3) {
    top: 29.5%;
  }

  .cmainCnt> :nth-child(2) {
    width: 80px;
  }

  .cmainCnt>h1 {
    font-size: 20px;
  }

  .cmainCnt {
    gap: 0.7rem;
    position: absolute;
    top: 2rem;
    height: 660px;
  }

  .backarrow {
    position: absolute;
    left: 2rem;
    top: 0.3rem;
  }

}


@media screen and (max-width: 820px) {
  .cAwhiteback {
    width: 700px;
    /* height: 590px; */
  }

  .epinput {
    width: 320px;
    height: 55px;
  }

  .epinput>input {
    width: 280px;
    height: 40px;
  }

  .userNfirst {
    width: 320px;
    height: 55px;
  }

  .userNfirst>input {
    width: 280px;
    height: 40px;
  }

  .khainwaladiv2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .backarrow {
    left: 3.1rem;
  }
}


@media screen and (max-width: 540px) {
  .cmainCnt {
    top: 5rem;
    margin-top: 0;
  }

  .cAwhiteback {
    width: 101vw;
    height: 100vh;
    position: relative;
    border-radius: 0px;
  }

  .proimage>:nth-child(3) {
    top: 29%;
  }

  .backarrow {
    left: 12.1rem;
    top: -3rem;
  }


  .userNinputs {
    flex-direction: column;
  }

  .Epasinputs {
    flex-direction: column;
  }

  .khainwaladiv2 {
    flex-basis: 50%;
  }

  .epinput {
    width: 356px;
    height: 50px;
  }

  .epinput>input {
    width: 320px;
    height: 40px;
  }

  .userNfirst {
    width: 356px;
    height: 50px;
  }

  .userNfirst>input {
    width: 310px;
    height: 40px;
  }

  .khainwaladiv2>p {
    font-size: 12px;
    padding-top: 10px;
    align-self: center;
  }

  .createaccount_checkbox {
    width: 300px;
    margin: 5%;
    margin-top: 0% !important;
  }

}

@media screen and (max-width: 414px) {
  .epinput {
    width: 320.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .errordiv>:nth-last-child(1) {
    align-self: flex-start;
  }

  .proimage>:nth-child(3) {
    top: 27.9%;
  }

  .epinput>input {
    width: 340px;
    height: 40px;
  }

  .userNfirst {
    width: 320.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .userNfirst>input {
    width: 270px;
    height: 40px;
  }

  .khainwaladiv2>p {
    font-size: 10.3px;
    padding-top: 10px;
  }

  /* .subbtn3{
    margin-top: -1.9rem;
  } */

  .cmainCnt>.havean {
    font-size: 15px;
  }

  .cmainCnt {
    position: absolute;
  }

  .proimage>:nth-child(1) {
    width: 100px;
  }

  .proimage>:nth-child(2) {
    width: 25px;
    /* top: 3.5rem; */
  }

  .cmainCnt> :nth-child(2) {
    width: 70px;
  }

  .cmainCnt>h1 {
    font-size: 20px;
  }

}

@media screen and (max-width: 375px) {
  .cmainCnt {
    gap: 0.5rem;
  }

  .backarrow {
    left: 13rem;
    top: -1rem;
  }

  .proimage>:nth-child(3) {
    top: 27%;
  }

}