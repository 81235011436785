.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 6rem;
  padding: 1rem;
  gap: 1rem;
}

.about>h2 {
  font-size: 2.1rem;
  color: #091011;
  font-weight: 700;
  text-align: center;

}

.about>p {
  width: 40%;
  text-align: center;
  color: #adbabc;
  margin-bottom: 20px;
}

.projects {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5rem;
  flex-direction: row;
  margin-top: 1rem;
}

.projects>div>h1 {
  color: #0dc3d7;
  font-size: 3rem;
  font-weight: bold;
}

.projects>div> :nth-child(2) {
  padding-bottom: 10px;

}



.projects>.firstdiv>* {
  text-align: right;
  font-weight: 500;

}

.projects>.seconddiv>* {
  text-align: left;
  font-weight: 500;
}

/* we are section */

.WeAre {
  background-color: #fafafa;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 4rem;
  gap: 1rem;


}

.ERROR {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 1rem;

}

.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.right>.btn1 {
  margin-top: 1rem;
}



.WeAre>.ERROR>div>h4 {
  color: #0dc3d7;
  font-weight: 500;
}

.WeAre>.ERROR>div>h1 {
  font-size: 2.5rem;
  color: #091011;
  font-weight: 700;
}

.WeAre>.right>div>p {
  color:
    #091011;
}



/* Card SECTION */

.Cardlist {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding-right: -3rem;
  text-align: center;
  margin-top: -8rem;
  flex-wrap: wrap;
}

.cardlist_up {
  padding-top: 200px;
}

.Cards>p {
  color: #91a0a3;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Cards>h2 {
  font-weight: 600;
  color: #091011;
  font-size: 20px;
}

.Cards>img {
  width: 100%;
  height: 320px;
  border-radius: 25px 25px 0px 0px
}

.Cards {
  box-shadow: 0px 3px 5px rgba(158, 158, 158, 0.568);
  width: 270px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.6rem;
  border-radius: 25px;
  margin: 1rem;
  padding-bottom: 1rem;
  transition: all 0.5s ease-in-out;
  padding: 0 5px;
  padding-bottom: 10px;
}

/* .Cards > div:hover {
  transform: scale(1.1);
} */

/* underline */
.firstdiv> :nth-child(3) {
  display: flex;
  justify-content: end;
  text-align: right;
}

.firstdiv,
.seconddiv>p {
  color: #091011;
}

/* media query */

@media screen and (max-width: 1024px) {
  .about>p {
    width: 60%;
  }

  .projects {
    gap: 1.9rem;
  }

  .WeAre>.ERROR>div>h1 {
    font-size: 2.1rem;
  }

  .WeAre>.right>div>p {
    font-size: 14px;
  }


}

@media screen and (max-width: 912px) {


  .aboutimg {
    transform: scale(0.9);
  }

  .projects {
    gap: 1rem;
    flex-direction: column;
  }

  .projects>div>h1 {
    font-size: 3rem;

  }

  .WeAre>.ERROR>div>h1 {
    font-size: 2rem;
  }



  .ERROR {
    padding-left: 2rem;
  }

  .right>.khenidiv>:nth-child(1) {
    font-size: 11px;

  }

  .Cards>div {
    width: 320px;

  }

  .ERROR>div {
    margin-left: 4rem;
  }

  .firstdiv {
    transform: scale(1.1);
    border-bottom: 1px solid #0dc3d7;
    padding-bottom: 7px;

  }

  .seconddiv {
    transform: scale(1.1);
    border-top: 1px solid #0dc3d7;
    padding-bottom: 5px;
  }
}



@media screen and (max-width: 768px) {
  .about>p {
    width: 70%;
  }

  .aboutimg> {
    transform: scale(0.9);
  }

  .projects {
    gap: 1rem;
    flex-direction: column;
  }

  .projects>div>h1 {
    font-size: 3rem;

  }

  .WeAre>.ERROR>div>h1 {
    font-size: 1.9rem;
  }



  .ERROR {
    padding-left: 2rem;
  }

  .right>.khenidiv>:nth-child(1) {
    font-size: 11px;

  }

  .Cards>div {
    width: 320px;

  }

  .ERROR>div {
    margin-left: 1.1rem;
  }

  .firstdiv {
    transform: scale(1.1);
    border-bottom: 1px solid #0dc3d7;
    padding-bottom: 7px;

  }

  .seconddiv {
    transform: scale(1.1);
    border-top: 1px solid #0dc3d7;
    padding-bottom: 5px;
  }



}


@media screen and (max-width: 540px) {
  .about>p {
    width: 80%;
  }

  .Cards {
    width: 350px
  }

  .cardlist_up {
    padding-top: 0;
  }

  .about>h2 {
    font-size: 1.9rem;
  }

  .about>p {
    font-size: 11px;
  }

  .about {
    margin-top: 3rem;
  }

  .aboutimg {
    transform: scale(0.8);
  }

  .projects>div>h1 {
    font-size: 2.5rem;

  }

  .projects>div>p {
    font-size: 14px;
  }

  .WeAre {
    flex-direction: column;
  }

  .ERROR {
    padding-left: 0.1rem;
    padding-top: 2rem;
  }

  .right>.khenidiv>:nth-child(1) {
    font-size: 12px;
  }

  .khenidiv {
    margin-top: -13rem;
    margin-left: 2rem;
  }

}


@media screen and (max-width: 414px) {
  .aboutimg {
    transform: scale(0.6);
  }

  .about>h2 {
    font-size: 1.8rem;
  }

  .about>p {
    font-size: 12px;
  }

  .projects>div>h1 {
    font-size: 1.8rem;

  }

  .firstdiv {
    margin-bottom: -2rem;
  }

  .seconddiv {
    margin-top: -3rem;
  }

  .projects>div>p {
    font-size: 12px;
  }

  .WeAre>.ERROR>div>h1 {
    font-size: 1.5rem;
  }

  .ERROR {
    padding-left: -1rem;
    margin-left: -3.5rem;
  }


  .right>.khenidiv>:nth-child(1) {
    font-size: 11px;
  }

  .ERROR {
    flex: 3;
  }

  .khenidiv {
    flex: 1;
  }

  .khenidiv {
    padding-left: -.1.2rem;
    width: 90%;
  }
}


@media screen and (max-width: 375px) {
  .ERROR {
    margin-left: -1.9rem;
  }

}