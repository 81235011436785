.vendornav {
  background-color: #007cc0;
  padding: 2%;
  color: white;
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: flex-start;
  gap: 42%;
  position: relative;
}

.vendor_profile {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 50%;
  text-align: center;


}

.vendor_profile>h2 {
  margin-bottom: 5px;
}

.vendor_job {
  border: 1px solid #007cc0;
  padding: 3% 2%;
  background-color: #e6eff4;
  border-radius: 5px;
  color: rgb(60, 59, 59);
  margin-bottom: 5px;

}

.vendorjob_rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.vendor_img {
  transform: none;
  border-radius: 50%;
  width: 35%;
  height: 20vh;
  margin: 0 auto 10px auto;
  background-color: rgb(212, 209, 209);
}

.vendordetails_list {
  display: flex;
  list-style-type: none;
  justify-content: space-around;
  gap: 0%;
  margin-top: 2%;
}

.vendordetails_list>li {
  width: 50%;
  text-align: center;
}

.vendordetails_list>li a {
  color: #373d3d;
  text-decoration: none;
  display: block;
  padding-bottom: 6%;
  border-bottom: 1px solid #373d3d;


}

.vendordetails_list>li>a.active {
  color: #0dc3dc;
  border-bottom: 1px solid #0dc3dc;
}

.vendorprofile_main {
  width: 60%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 2px 2px 5px #373d3d;
  background-color: #f3f3f3;
  transform: translate(0%, -5%);
  border-radius: 5px;
  padding: 2%;
  min-height: 82vh;
  max-height: max-content;
  position: relative
}

.vendorprofile_bgimage {
  width: 100%;
  background-image: url(/src/Assets/backoverlayimg.png);
  background-size: cover;
  min-height: 80vh;
  max-height: max-content;
}

/* .profilecntnt{
    padding: 0 4rem;
    margin-top: 2rem;
} */


/* .vlistbtn{
    display: flex;
    gap: 0.5rem;
}

.vlistbtn>li{
    list-style: none;
}
.vlistbtn>li>a{
    text-decoration: none;
    color: #7F8C93;
    font-size: 16px;
    font-weight: 600;
}

.vlistbtn>li>a.active{
    color: #091011;
} */
/* 
.activemyjob>li>a.active{
        color: #7F8C93;
} */
@media(max-width:1250px) {
  .vendor_img {
    width: 45%;

  }

  .vendorprofile_main {
    width: 65%
  }

}

@media(max-width:1050px) {
  .vendor_img {
    height: 20vh;
  }

  .vendor_profile {
    width: 60%
  }

  .vendorprofile_main {
    width: 70%
  }

}

@media(max-width:1050px) {
  .vendorprofile_main {
    width: 75%
  }

}

@media(max-width:700px) {
  .vendor_img {
    height: 17vh;
  }

  .vendor_profile {
    width: 70%
  }

  .vendorprofile_main {
    width: 80%
  }
}

@media (max-width:520px) {
  .vendornav {
    padding: 5% 2%;
    height: 15vh;
    gap: 30%;
  }

  .vendor_img {
    width: 35%;
    transform: translate(0%, -50%);
    height: 18vh;
    margin-bottom: -30px;

  }

  .vendor_profile {
    width: 90%;
  }

  .vendordetails_list {
    margin-top: 5%;
  }

  .vendorprofile_main {
    width: 100%;
    transform: none;
  }

  .vendorprofile_bgimage {
    background-image: none;
  }
}