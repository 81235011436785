.vmycontainer {
    /* display: flex;
    flex-direction: column; */
    padding: 0 4rem;
    margin-bottom: 3%;

  }
  
  /* .filtermodal{
    position: absolute;
    right: 0;
    top: 0;
  } */
  
  .vmyjobcntnt {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    
  }
  
  .vmyjobs {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .vendortopdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .vendorjobicon{
    display: flex;
    align-items: center;
  }
  
  .vmyjobs > h2 {
    color: #171d1e;
    font-weight: 600;
    font-size: 24px;
    margin-right: 2rem;
    z-index: -1;
  }
  
  .vendorposts {
    width: 25%;
    padding: 4% 2%;

    height: 40px;
    background: #ebfcf3;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .vendorposts > h3 {
    color: #1aa75c;
    font-weight: 600;
    font-size: 20px;
  }
  
  .vendorposts,
  .vendorConfirmed,
  .vendorWithdrawn,
  .vendorTotalCancelled > span {
    font-size: 14px;
    font-weight: 400;
    color: #171d1e;
  }
  
  .vendorConfirmed {
    display: flex;
    align-items: center;
    justify-content: center;
    width:25%;
    padding: 4% 2%;

    height: 40px;
    background: #e2f5ff;
    border-radius: 6px;
    gap: 5px;
  }
  
  .vendorConfirmed > h3 {
    color: #007cc0;
    font-size: 20px;
    font-weight: 600;
  }
  .vendorWithdrawn {
    display: flex;
    align-items: center;
    justify-content: center;
    width:25%;
    padding: 4% 2%;

    height: 40px;
    background: #fcf6e9;
    border-radius: 6px;
    gap: 5px;
  }
  
  .vendorWithdrawn > h3 {
    color: #df9c13;
    font-size: 20px;
    font-weight: 600;
  }
  .vendorTotalCancelled {
    display: flex;
    align-items: center;
    justify-content: center;
    width:25%;
    padding: 4% 2%;
    height: 40px;
    background: #fef2f2;
    border-radius: 6px;
    gap: 5px;
  }
  
  .vendorTotalCancelled > h3 {
    color: #d44545;
    font-size: 20px;
    font-weight: 600;
  }
  @media(max-width:681px){
    .vmyjobs {
      display: block;
      width:90%;
    }
      
      .vendorposts,
    .vendorConfirmed,
    .vendorWithdrawn,
    .vendorTotalCancelled {
      width:47%;
      margin:1%;
      padding:5% 2%;
     float:left;
    }
    .vendortopdiv{
      align-items: flex-start;
    }
    .vmyjobs>h2{
      margin-bottom:2%;
    }
    .vendorjobicon{
      align-items: flex-start;
    }
    }
    @media screen and (max-width: 450px){
      .vmycontainer{
        padding: 0 10px;
      }
      .vendorposts,
    .vendorConfirmed,
    .vendorWithdrawn,
    .vendorTotalCancelled {
      padding:12% 2%;
    }
    }
    
    
    
  
  
  
  