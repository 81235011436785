.couplebox {
  /* width: max-content; */
  /* height: max-content; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.201);


}

.couplesprofile {
  display: grid;
  /* grid-template-columns: repeat(4,1fr); */
  grid-template-columns: 23.7% 23.7% 23.7% 23.7%;
  gap: 1.8%;
  margin-top: 2rem;
  margin-left: 2%;
  margin-right: 2%;
}

.cboxcntnt {
  /* width: 397px; */
  /* height: 247px; */
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.cboxcntnt>.cname>.clocation>h3 {
  color: #171d1e;
}

.cbudget {
  color: rgb(79, 78, 78);
  font-size: 14px;
}

.cbudget>span {
  color: black;
  font-size: 15px;
  margin: 5px
}

.couplebox>.cboxcntnt>.cname {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
}

.cname>img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.cmiles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cmiles>span {
  color: #7f8c93;
  font-size: 14px;
  font-weight: 400;
}

.clocation {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailbtn>button {
  align-self: flex-end;
  width: 100%;
  background: #00bfd3;
  border-radius: 5px;
  padding: 2%;
  font-size: 15px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;

}

.csingerbtn>button {
  padding: 2%;
  margin: 2% 1%;
  background: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}

/* .csingerbtn {
    display: flex;
    gap: 5px; 
  } */


.csinger {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.csinger>h3 {
  color: #171d1e;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.vendorfilter_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media screen and (max-width: 1250px) {
  .couplesprofile {
    grid-template-columns: 32% 32% 32%;
    gap: 1.9%;
    margin-left: 1.9%;
    margin-right: 1.9%;

  }

}

@media screen and (max-width:1010px) {
  .couplesprofile {
    grid-template-columns: 49% 49%;
    gap: 2%;

  }

}

@media screen and (max-width: 681px) {
  .couplesprofile {
    grid-template-columns: auto;
  }

  .couplebox {
    margin: 2%;
  }
}