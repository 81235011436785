
.nextslider {
    display: flex;
    align-items: center;
    /* margin-top: 3rem; */
    justify-content: center;
    gap:5px;
    margin:2%;
    grid-column: 1 / 5;



  }
  
  .sliderbuttons{
    cursor: pointer;
    justify-content: center;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width:max-content;
  
  }
  .sliderbuttons:hover{
  box-shadow: 1px 1px 3px  #011c2a;
  }
  .nf_sliderbuttons{
    cursor: pointer;
    justify-content: center;
    font-size:13px;
    display: flex;
    align-items: center;
    gap: 1rem;
    width:max-content;
    border-radius: 50%;
  
  }
  .nf_sliderbuttons:hover{
  box-shadow: 1px 1px 3px  #011c2a;
  }
  .activepage{
    background-color: #007CC0 !important;
    color:white;
    border: 1px solid #007CC0 !important;

  }
  .nf_activepage{
    color: #007CC0 !important;

  }

  .linkbuttons{
    /* width: 40px; */
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    gap: 1rem;
   justify-content: center;
   padding: 5px 13px;
  }
  .nf_linkbuttons{
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    gap: 1rem;
   justify-content: center;
   font-size:13px;
   border-radius: 50%;
   padding: 5px 13px;
  }
 .disable{
  background-color: rgba(54, 53, 53, 0.479);
  color:rgba(22, 22, 22, 0.73);
  border:none
 }
 .nf_disable{
  background-color: rgba(205, 201, 201, 0.751);
  color:rgba(22, 22, 22, 0.73);
  border:none
 }
 @media(max-width:1250px){
  .nextslider{
    grid-column: 1 / 4;

  }
 }
 @media(max-width:1010px){
  .nextslider{
    grid-column: 1 / 3;

  }
 }
 @media(max-width:681px){
  .nextslider{
    grid-column: 1 / 2;

  }
 }
  @media(max-width:500px){
    .linkbuttons{
      padding: 0px 5px;
      height:30px;
    }
  }


  

  