.notificationmodal_content{
    display:block;
    position: absolute;
    top:7vh;
    right:2.5%;
    background-color:#007cc0;
    color: #ffffff;
    border-radius: 5px;
    min-width:320px;
    padding-top:5%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.notification_drop{
    width:20px;
    height:20px;
    background-color:#007cc0;
    position: absolute;
    top:-1%;
    right:17%;
    transform: rotate(45deg);
  }
.nf_heading{
    padding:6%;
    padding-top: 0%;
    border-bottom:1px solid rgba(240, 239, 239, 0.915)
}
.notification_detail{
    border-bottom:1px solid rgba(212, 210, 210, 0.465);
    cursor: pointer;
}
.notification_detail:hover{
    background-color: #015e90;
}
.nf_title{
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap:10px;
}
.nf_msg{
    font-size: 14px;
    margin-left:10px
}
.nf_time{
    font-size: 12px;
    margin:10px;
    margin-left:10px;
    color:rgb(224, 223, 223)
}