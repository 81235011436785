  /* .dropdown {
    position: relative;
    display: inline-block;
  } */

  .dropdown-content {
    display: block;
    position: absolute;
    top: 8.2vh;
    right: -3%;
    background-color: #007cc0;
    color: #ffffff;
    border-radius: 5px;
    min-width: 250px;
    padding-top: 5%;
    padding-bottom: 2%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: -1;
  }

  .drop {
    width: 20px;
    height: 20px;
    background-color: #007cc0;
    position: absolute;
    top: -2%;
    right: 17%;
    transform: rotate(45deg);
  }

  .dropdown-content a {
    font-size: 14px;
    font-weight: 400;
    color: white;
    padding: 9px 30px;
    text-decoration: none;
    display: flex;
    gap: 10px;
    align-items: center;

  }


  .dropdown-content a:hover {
    background-color: rgba(0, 0, 0, 0.305);
    /* color:black; */
    border-radius: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.544);
  }

  .dropdown-content_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    object-fit: cover;
    width: 84px;
    height: 84px;
    border-radius: 100px;
    border: 1px solid white;
    background-color: rgb(212, 210, 210);

  }

  .dropdown-content_heading {
    text-align: center;
    margin-bottom: 5%;
    font-size: 20px;
  }

  .dropdown-content>div {
    text-align: center;
  }

  .icon>.css-i4bv87-MuiSvgIcon-root {
    margin-bottom: -7px;
    margin-right: 8%;
  }

  @media (max-width:414px) {

    .dropdown-content {
      right: -11.5%;
    }

    .drop {
      right: 12%;
    }
  }