.jobconfirmed_bids {
  border: 1px solid rgba(0, 0, 0, 0.201);
  margin: 2%;
  padding: 2%;
  width: 45%;

}

.jobconfirmedbid_div {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;

}

.jobconfirmedbid_div>img {
  width: 23%;
  height: 23%;
}

.jobconfirmed_bidsright {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
}

.jobconfirmed_miles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.jobconfirmed_bidinfo {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.jobconfirmed_biddetails>p {
  color: #040404a3;
}

.jobconfirmed_biddetails>h4 {
  font-size: 16px;
}

.jobconfirmedbid_button>button {
  width: 46%;
  padding: 2%;
  margin: 5% 2%;
  background-color: #00bfd3;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
}

.jobconfirmedbid_button>button:hover {
  background-color: white;
  border: 1px solid #00bfd3;
  color: #00bfd3
}

.nodata>div {
  width: 25%;
}

.nodata>div>img {
  margin-bottom: 5%;
  width: 100%;
}

.nodata>div>h5 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.ratings {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ratings>h2 {
  font-size: 1.3em;
  margin-bottom: 8px;
}

.rating_data {
  width: 100%;
  margin-top: 2%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.1rem;
  color: #afafaf;

}

.rate_star {
  font-size: 3rem !important;
  margin-bottom: 25px;
}

.rate_text>label {
  font-weight: 600;
}

.rate_button {
  padding: 1% 5%;
  width: 30%;
  background-color: #00bfd3;
  color: white;
  border: 1px solid #00bfd3;
  border-radius: 5px;
  margin-bottom: 5%;
  cursor: pointer;

}

@media(max-width:700px) {
  .jobconfirmed_bids {
    width: 96%;
  }

}

@media(max-width:500px) {
  .nodata>div {
    width: 70%;
  }

  .nodata>div>img {
    margin-bottom: 5%;
    width: 100%;
  }

}