.Editprofileback {
  width: 840px;
  height: 574px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  gap: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Editprofilectn {
  width: 950px;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  padding: 2%;
}

.Editimage {
  margin: 15px 0;
}

.Editnav {
  background-color: #007cc0;
  padding: 2%;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 45%;
  position: relative;
}

.Editmainarrow {
  position: absolute;
  left: 5px;
  margin-top: -5px;
}



.editinputsDiv {
  display: flex;
  gap: 1rem;
}

.Editproinput {
  width: 370px;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Editproinput>input {
  width: 320px;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 10px;
}

.Editproinput>img {
  width: 25px;
  margin-left: 8px;
}

.Editproemailinput {
  width: 760px;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Editproemailinput>input {
  width: 710px;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 10px;
}

.Editproemailinput>img {
  width: 25px;
  margin-left: 8px;
}

.Cprofilebtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.Editimage>.profile {
  width: 110px;
  height: 110px;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
}


.Editimage>:nth-child(3) {
  position: absolute;
  top: 24%;
  right: 45%;
  cursor: pointer;
}

.Editimage>input {
  display: none;
}

@media screen and (max-width: 1024px) {
  .Editprofileback {
    height: 520px;
  }

  .Editimage>:nth-child(3) {
    top: 23%;
  }

  .maininputsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 912px) {
  .Editprofileback {
    width: 80vw;
    height: 520px;
  }

  .Editproinput {
    width: 35vw;
    height: 55px;
  }

  .Editproinput>input {
    width: 28vw;
    height: 40px
  }

  .Editproemailinput {
    width: 72vw;

  }

  .Editproemailinput>input {
    width: 65vw;
  }
}

@media screen and (max-width: 540px) {
  .Editprofileback {
    width: 101vw;
    height: 100vh;
    padding-top: 20px;
    border-radius: 0px;
    justify-content: flex-start;
  }

  .Editnav {
    padding: 5% 2%;
    /* height:50px; */
    gap: 35%;
  }

  .Editproinput,
  .Editproemailinput {
    width: 375px;
  }

  .maininputsDiv {
    flex-direction: column;
  }

  .Editproinput>input,
  .Editproemailinput>input {
    width: 325px;
    height: 50px;
  }

  .Cprofilectn {
    align-self: flex-start;
    margin-top: 2.5rem;
  }

  .Editimage>:nth-child(3) {
    top: 19%;
  }

  .editinputsDiv {
    flex-direction: column;
  }
}

@media screen and (max-width: 414px) {
  .Editnav {
    gap: 30%;
  }

  .Editproinput>input,
  .Editproemailinput>input {
    width: 270px;
    height: 50px;
  }

  .Editproinput,
  .Editproemailinput {
    width: 320.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .Cprofilebtns {
    flex-direction: column;

  }


  .Cprofilectn {
    margin-top: 6rem
  }

}

@media screen and (max-width: 375px) {
  .Cprofilectn {
    gap: 0.5rem
  }
}