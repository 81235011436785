.mapbox{
  width:300px;
  background: #ffffff;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 10px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  box-shadow:1px 1px 4px rgba(0, 0, 0, 0.201) ;
}
.map_pic{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:10%;
}
.map_pic>img{
  width:60px;
  height:60px;
  border-radius: 50%;
}

.map_profession{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.map_profession > h3{
  color: #171d1e;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}


.map_skill > button {
  padding: 2%;
  margin:2% 1%;
  background: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}
.map_detailbtn>button {
  align-self: flex-end;
  width:100%;
  background: #00bfd3;
  border-radius: 5px;
  padding:2%;
  font-size:15px;
  color: white;
  border: none;
  outline: none;
  cursor:pointer;
}

