.cnp {
    width: 485px;
    height: 491px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
  }
  .cnp > .texttt > h1 {
    color: #171d1e;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
  }
  
  .cnp > .texttt > p {
    color: #7f8c93;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    margin-top: 10px;
  }
  
.pcontnt{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media(max-width:500px){
  .cnp{
    width:auto;
    
  }
}
  