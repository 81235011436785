.mycontainer {
  display: flex;
  flex-direction: column;
}

/* .filtermodal{
  position: absolute;
  right: 0;
  top: 0;
} */

.myjobcntnt {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

}

.myjobs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 70%;
}

.topdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2%;
}

.jobicon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.myjobs>h2 {
  color: #171d1e;
  font-weight: 600;
  font-size: 24px;
  z-index: -1;
  width: 19%;
}

.posts {
  width: 25%;
  height: 40px;
  background: #ebfcf3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 4% 2%;
}

.posts>h3 {
  color: #1aa75c;
  font-weight: 600;
  font-size: 20px;
}

.posts,
.Confirmed,
.Withdrawn,
.TotalCancelled>span {
  font-size: 14px;
  font-weight: 400;
  color: #171d1e;
}

.Confirmed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 40px;
  background: #e2f5ff;
  border-radius: 6px;
  gap: 5px;
  padding: 4% 2%;

}

.Confirmed>h3 {
  color: #007cc0;
  font-size: 20px;
  font-weight: 600;
}

.Withdrawn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 40px;
  background: #fcf6e9;
  border-radius: 6px;
  gap: 5px;
  padding: 4% 2%;

}

.Withdrawn>h3 {
  color: #df9c13;
  font-size: 20px;
  font-weight: 600;
}

.TotalCancelled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 40px;
  background: #fef2f2;
  border-radius: 6px;
  gap: 5px;
  padding: 4% 2%;

}

.TotalCancelled>h3 {
  color: #d44545;
  font-size: 20px;
  font-weight: 600;
}

.filterbutton {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.filterbutton_filter {
  width: 40px;
  height: 40px;
  background: #007cc0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

#add,
#filter {
  margin-right: 5px;
  cursor: pointer;
}

@media(max-width:980px) {
  .myjobs>h2 {
    font-size: 22px;
    width: 28%
  }
}

@media(max-width:681px) {
  .myjobs {
    display: block;
    width: 90%;
  }

  .posts,
  .Confirmed,
  .Withdrawn,
  .TotalCancelled {
    width: 47%;
    margin: 1%;
    padding: 5% 2%;
    float: left;
  }

  .topdiv {
    align-items: flex-start;
  }

  .myjobs>h2 {
    margin-bottom: 2%;
    margin-right: 0 !important;
    width: auto
  }

  .jobicon {
    align-items: flex-start;
  }
}

@media screen and (max-width: 450px) {
  .mycontainer {
    padding: 0 10px;
  }

  .posts,
  .Confirmed,
  .Withdrawn,
  .TotalCancelled {
    padding: 12% 2%;
  }
}