.myCard1 {
  /* width: 427px; */
  /* height: 208px; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.201);

}

.jobCards {
  margin-top: 1.2rem;
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: auto auto auto auto;
  gap: 1.4rem;
  margin-right: 1.5%;
  margin-left: 1.5%;

}

.confirmdiv {
  /* width: 397px; */
  /* height: 178px; */
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.jobtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobtext>h3 {
  color: #171d1e;
  font-weight: 500;
  font-size: 16px;
}

.myjobbtns {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.myjobbtns>button {
  background: #F3F3F3;
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  padding: 1.5%;
  font-weight: 400;
  font-size: 14px;
  margin-right: 7px;

}

/* .myjobbtns > :nth-child(1) {
  width: 96px;
  height: 26px;
}
.myjobbtns > :nth-child(2) {
  width: 88px;
  height: 26px;
  font-weight: 400;
  font-size: 14px;
}
.myjobbtns > :nth-child(3) {
  width: 85px;
  height: 26px;
  font-weight: 400;
  font-size: 14px;
} */

.bugetdiv {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  clear: left;
}

.jobbtn>button {
  align-self: flex-end;
  width: 100%;
  background: #00bfd3;
  border-radius: 5px;
  padding: 2%;
  font-size: 17px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;

}


.div1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.div1>span {
  color: #7f8c93;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 20px
}

.div1>h4 {
  color: #171d1e;
  font-weight: 400;
  font-size: 16px;
}

@media screen and (max-width: 1250px) {
  .jobCards {
    grid-template-columns: 32% 32% 32%;
    gap: 1.9%;
    margin-left: 1.9%;
    margin-right: 1.9%;

  }

}

@media screen and (max-width:1010px) {
  .jobCards {
    grid-template-columns: 49% 49%;
    gap: 2%;
    /* margin-right:2.5%;
  margin-left:2.5%; */

  }

}

@media screen and (max-width: 681px) {
  .bugetdiv {
    gap: 2rem
  }

  .jobCards {
    grid-template-columns: auto;
    margin: 5% 10%;
  }
}

@media(max-width:450px) {

  .jobCards {
    margin: 5% 1%;
  }
}

@media(max-width:375px) {
  .bugetdiv {
    gap: 1rem
  }
}