.navdiv {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 4rem;
  background-color: #ffffff;
  z-index: 100000;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05));
}

.homelogo {
  display: flex;
  align-items: center;
}

.homelogo>:nth-last-child(1) {
  display: none;
}

.homenav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10000;
  position: relative;

}

.navdiv>.homelogo>a>img {
  width: 65%;
}

.menulinks>li {
  margin: 0 1.3rem;
  list-style: none;
  color: #091011;
}

/* .modallogout{
  z-index: 100;
  position: fixed;
  top: 100%;
  right: 5%;
} */

.menulinks {
  display: flex;
  align-items: center;
}

.menulinks>li>a {
  text-decoration: none;
  color: #373d3d;
  font-weight: bold;
  padding-bottom: 1.2rem;
  overflow: hidden;
}

.menulinks>li>a:hover {
  cursor: pointer;
}

.menulinks>li>a.active {
  color: #0dc3dc;
  border-bottom: 2.8px solid #0dc3dc;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.activenav {
  color: #0dc3dc !important;
  border-bottom: 2.8px solid #0dc3dc;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.SIPP {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.profilemainimg {
  cursor: pointer;
  overflow: hidden;
}

.profilemainimg>img {
  object-fit: cover;
  width: 42px;
  height: 42px;
  border-radius: 100px;

}

.profilemainimg:active {
  transform: scale(0.9);
}


@media screen and (max-width: 834px) {
  .menulinks>.homelogo>a>img {
    width: 100%;
  }

  .searchicon {
    width: 210px;
    height: 40px;
  }

  .searchicon>input {
    width: 190px;
    height: 32px;
  }
}

@media screen and (max-width: 768px) {
  .homelogo>:nth-last-child(1) {
    display: block;
  }

  .homelogo>:nth-last-child(2) {
    display: none;
  }

  .navdiv {
    padding: 0 2rem;
  }

  .homenav {
    justify-content: flex-end;
    z-index: 1000;
  }

  .menulinks {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #091011b7;
    position: fixed;
    top: 100%;
    left: 0;
    width: 30%;
    height: 100px;
    z-index: -1;
    transform: translate(-110%);
    transition: 0.3s ease-in-out;
  }

  .menuhides {
    transform: translate(0);
  }


  .menulinks>li>a.active {
    color: #0dc3dc;
    border: none;
  }

  .menulinks>li>a {
    color: #ffffff;
  }
}

@media screen and (max-width: 428px) {
  .SIPP {
    gap: 1rem;
  }

  .searchicon {
    width: 150px;
  }

  .searchicon>input {
    width: 100%;
  }
}