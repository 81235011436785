.pac-container {
    z-index: 100000;
}

.filter_data {
    text-align: left;
}

.filter_name {
    height: 23px;
    width: 68px;
    letter-spacing: 0;
    line-height: 23px;
    color: #171D1E;
    font-size: 16px;
    font-weight: bold;

}

.filter_input {
    border: none;
    outline: none;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin: 10px 0;
    font-size: 1.1rem;
    color: #2a2929da;


}

.filter_checkbox {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 15px 0;
}

.filter_checkbox_data {
    color: #2a2929da;
    font-size: 16px;
    margin: 7px 0;
    display: flex;
    align-items: center;
    gap: 8px
}

.filter_price {
    color: #5F5F5F;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.filter_button {
    border-radius: 4px;
    padding: 10px 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: #00bfd3;
    color: white;
    border: 1px solid #00bfd3;
    margin-right: 3%;
    width: 97%;
    cursor: pointer;
    margin: 10px;

}

.filter_button2 {
    border-radius: 4px;
    padding: 10px 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: white;
    color: #00bfd3;
    border: 1px solid #00bfd3;
    margin-right: 3%;
    width: 97%;
    cursor: pointer;
    margin: 10px;

}

.filter_button:hover {
    background: #1976d2;
    color: white;
    border: 1px solid #1976d2;


}

.filter_button2:hover {
    border: 1px solid #1976d2;
    color: #1976d2
}

.filter_clearbtn {
    color: var(--red);
    font-size: 17px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    font-family: DM sans;
    margin: 20px 8%;
    width: 84%
}

.selected {
    background: var(--red);
    color: #FFF !important;
}

.filter_location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #171D1E;
    font-size: 16px;
    font-weight: bold;

}

.filter_heading {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0;

}

.filterlistings {
    flex-wrap: wrap;
}

.filter_places {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.filter_checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

@media(max-width:600px) {
    .filter_button {
        padding: 10px 13px;
        font-size: 12px;
        margin-right: 0%;

    }

}