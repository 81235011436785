
.Vendorprofession_Card{
    border:1px solid rgb(185, 183, 183);
    border-radius: 5px;
    padding: 3%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    margin:2%;
  }
.vendorprofession_btns>button{
    padding:1%;
    margin:1% 1.5% 1% 0%;
    width:120px;
    background-color: rgb(232, 233, 233);
    border:1px solid rgb(174, 173, 173)
}
.vendorprofession_job{
    display: flex;
    gap:20%;
}
.vendorprofession_job>p{
    color:#040404a3;
  
}
.vendorprofession_job>p>b{
    font-size: 18px;
    color:black
}
@media(max-width:500px){
    .vendorprofession_btns>button{
        width:80px;
    }
}