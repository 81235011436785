.vendorprofilebackw {
    width:70vw;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vendorscreenback{
    background: url('/src/Assets/backoverlayimg.png');
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding:3%;
     
}

  .vendorprofilectn {
    width: 950px;
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    padding: 2%;
  }
  
  .vmainarrow {
    position: absolute;
    left:10px;
    top:20px;
  }
  
  .cprotext > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #171d1e;
  }
  
  .cprotext > p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #171d1e;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .VendorClogo {
    width: 100px;
    margin-left: 3rem;
  }
  
  .vendorproinput {
    width: 383px;
    height: 55px;
    background: #f2f5f7;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vendorfirstproinput {
    width: 780px;
    height: 55px;
    background: #f2f5f7;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgupload_div{
    width:65vw;
  }
  .imgupload{
    border:1px dotted #00bfd3;
    width:92px;
    height:100px;
    text-align: center;
    position:relative;
    float:left;
    margin:1%;
  }
  .imgbox{
    width:92px;
    height:100px;
    text-align: center;
    position:relative;
    float:left;
    margin:1%;
  
  }
  .videobox{
    width:184px;
    height:100px;
    text-align: center;
    position:relative;
    float:left;
    margin:1%;
  
  }
  #deleteimg{
    position:absolute;
    top:0%;
    right:0%;
    color:white; 
  }
  #deletevideo{
    position:absolute;
    top:0%;
    right:0%;
    color:white; 
  }
  #addimg{
    position:absolute;
    top:35%;
    left:30%;
    color:#00bfd3;
    font-size: 2rem;
  }
  
  #file {
   display:none
}
  
  .firstproinput > img {
    width: 25px;
    margin-left: 8px;
  }
  
  .textarea > input {
    width: 950px;
    height: 128px;
    background: #f2f5f7;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    outline: none;
    padding: 13px;
    position: relative;
  }
  
  .textarea > input::placeholder {
    position: absolute;
  }
  
  .Cprofilebtns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .skipbtn > button {
    width: 165px;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    text-align: center;
    color: #171d1e;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
  }
  
  .skipbtn > button:active {
    transform: scale(0.9);
  }
     .inputfgrp{
      display: inline-block;
      width:max-content
     }
     .inputf{
      width:32vw !important;
      margin:5px !important;
     }
     .inputT{
      width:65vw !important;
      margin:2px
     }
  
  @media screen and (max-width: 1024px) {
    .vendorprofilebackw{
      width:80vw;
    }
  
    .textarea > input {
      width: 780px;
    }
  
    .firstproinput {
      width: 276px;
    }
  
    .maininputsDiv,
    .textarea {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .vmainarrow {
      margin-left: 1rem;
      margin-top: 0.8rem;
    }
  }
  
  @media screen and (max-width: 912px) {

    .vendorprofilebackw {
      width: 85vw;
    }
  
    .textarea > input {
      width: 650px;
    }
  
    .firstproinput {
      width: 210px;
    }
  }
  
  @media screen and (max-width: 540px) {
    
    .vendorprofilebackw {
      width: 101vw;
      border-radius: 0px;
    }
    .vendorscreenback{
      padding:0%;
    }
    .inputf{
      width:80vw !important;
      margin:5px;
     }
     .inputT{
      width:80vw !important;
      margin:2px
     }
     .inputfgrp{
      display: inline-block;
      width:min-content
     }
    .textarea > input {
      width: 450px;
      height: 90px;
    }
  
    .firstproinput {
      width: 450px;
    }
  
    .maininputsDiv {
      flex-direction: column;
    }
  
    .firstproinput > input {
      width: 400px;
      height: 50px;
    }
  
    .Cprofilectn {
      align-self: flex-start;
      margin-top: 2.5rem;
    }
  }
  
  @media screen and (max-width: 414px) {
    .textarea > input {
      width: 320.37px;
      height: 75.17px;
      background: transparent;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0px;
    }
  
    .VendorClogo {
      width: 80px;
      margin-left: 3.6rem
    }
    .VClogo{
      width:80px;
      margin-left:3.6rem;
    }
  
    .firstproinput > input {
      width: 300px;
      height: 50px;
    }
    .firstproinput {
      width: 320.37px;
       height: 55.17px;
       background: transparent;
       border-top: 0px;
       border-left: 0px;
       border-right: 0px;
       border-bottom: 1px solid rgba(0, 0, 0, 0.1);
       border-radius: 0px;
    }
  
      .Cprofilebtns{
        flex-direction: column;
  
      }
  
      .skipbtn > button {
        width: 315px;
      }
  
      .Cprofilectn{
        margin-top: 6rem
      }
    
  }
  
  @media screen and (max-width: 375px){
    
  
    .Cprofilectn{
        gap: 0.5rem
    }
  }
  