.back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  width: 908px;
  height: 517px;
  padding: 20px;
  position: relative;
}

.main>img {
  width: 100px;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.main>h1 {
  color: #171D1E;
  font-size: 32px;
  font-weight: 700;
}

.proimg>div>img {
  width: 200px;
  flex-direction: row;
  cursor: pointer;
}


.proimg>div {
  text-align: center;
  padding: 1.8rem;
}

.proimg>div>h3:active {
  transform: scale(0.9);
  color: rgb(122, 119, 119);
}

.proimg>div>h3 {
  cursor: pointer;
  font-size: 1.7rem;
  color: #171D1E;
  font-weight: 500;
  padding-top: 10px;
}

.proimg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}


@media screen and (max-width: 912px) {
  .back {
    width: 690px;
    height: 517px;
  }
}

@media screen and (max-width: 540px) {
  .back {
    width: 101vw;
    height: 100vh;
    border-radius: 0px;
  }

  .main>img {
    width: 85px;
  }

  .proimg {
    flex-direction: column;
    gap: 0.5rem;

  }

  .proimg>div>img {
    width: 180px;
    flex-direction: row;
  }

  .proimg>div>h3 {
    font-size: 1.3rem;

  }

  .main>h1 {

    font-size: 25px;

  }

  .proimg>div {
    padding: 1.4rem;
  }


}


@media screen and (max-width: 428px) {
  .proimg>div>img {
    width: 150px;
  }

  .proimg>div>h3 {
    font-size: 1.9rem;

  }

  .main>h1 {
    font-size: 25px;

  }
}

@media screen and (max-width: 375px) {
  .proimg>div>img {
    width: 130px;
  }
}