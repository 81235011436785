.couplenav {
  background-color: #007cc0;
  padding: 2%;
  color: white;
  width: 100%;
  /* height:20vh; */
  display: flex;
  justify-content: flex-start;
  gap: 42%;
  position: relative;
}

.couple_profile {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 43%;
  text-align: center;


}

.couple_profile>h2 {
  margin-top: 0%;
  font-size: 28px;
}

.couple_img {
  width: 50%;
  height: 21vh;
  transform: none;
  border-radius: 50%;
  background-color: rgb(213, 211, 211);
  margin: auto;
}

.coupleprofile_main {
  width: 50%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 2px 2px 5px #373d3d;
  background-color: #fff;
  /* transform: translate(0%,-5%); */
  border-radius: 5px;
  padding: 2%;
  padding-bottom: 70px;
  position: relative;

}

.coupleprofile_bgimage {
  width: 100%;
  background-image: url(/src/Assets/backoverlayimg.png);
  background-size: cover;
}

.coupleprofile_details {
  display: flex;
  justify-content: space-around;
  margin: 5%;
}

.coupleprofile_memories {
  margin: 2%;
}

.coupleprofile_details>div>h4,
.coupleprofile_memories>h4 {
  font-size: 19px;
  margin: 5px 0;
}

.coupleprofile_details>div>p,
.coupleprofile_memories>p {
  color: #454949c2
}

.postedrequirement>h2 {
  margin-left: 2%;
}

.coupleprofile_posts {
  width: 46%;
  padding: 5%;
  background: #ebfcf3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  float: left;
  margin: 2%;

}

.coupleprofile_Confirmed {
  width: 46%;
  padding: 5%;
  background: #ebfcf3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  float: left;
  margin: 2%;
}

.coupleprofile_Withdrawn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46%;
  padding: 5%;
  background: #fcf6e9;
  border-radius: 6px;
  gap: 5px;
  float: left;
  margin: 2%;

}

.coupleprofile_TotalCancelled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46%;
  padding: 5%;
  background: #fef2f2;
  border-radius: 6px;
  gap: 5px;
  margin: 2%;
  float: left;
}



.coupleprofile_posts,
.coupleprofile_Confirmed,
.coupleprofile_Withdrawn,
.coupleprofile_TotalCancelled>span {
  font-size: 14px;
  font-weight: 400;
  color: #171d1e;
}



.coupleprofile_Confirmed>h3 {
  color: #007cc0;
  font-size: 25px;
  font-weight: 600;
}


.coupleprofile_Withdrawn>h3 {
  color: #df9c13;
  font-size: 25px;
  font-weight: 600;
}


.coupleprofile_TotalCancelled>h3 {
  color: #d44545;
  font-size: 25px;
  font-weight: 600;
}

.coupleprofile_posts>h3 {
  color: #1aa75c;
  font-weight: 600;
  font-size: 25px;
}

.coupleprofileCard1 {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.201);
  border-radius: 6px;
  padding: 4%;
}

.coupleprofileCards {
  margin: 0.5rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 1.2rem
}

.coupleprofile_confirmdiv {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.coupleprofile_jobtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coupleprofile_jobtext>h3 {
  color: #171d1e;
  font-weight: 600;
  font-size: 23px;
}

.coupleprofile_jobbtns>button {
  margin: 5px;
  padding: 1%;

}

.coupleprofile_bugetdiv {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1rem;
  margin-bottom: 8px;
}

.coupleprofile_jobbtn {
  align-self: flex-end;
}

.coupleprofile_div1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.coupleprofile_div1>span {
  color: #7f8c93;
  font-weight: 400;
  font-size: 14px;
}

.coupleprofile_div1>h4 {
  color: #171d1e;
  font-weight: 600;
  font-size: 16px;
}

.coupleprofile_details2 {
  color: rgba(14, 14, 14, 0.756)
}

.coupleprofile_btns {
  border: 1px solid #00bfd3;
  padding: 2.5%;
  background-color: #fff;
  font-size: 17px;
  margin: 2%;
  width: 94%;
  color: #00bfd3;
  cursor: pointer;
  border-radius: 10px;
}

.coupleprofile_btns:hover {
  background-color: #00bfd3;
  color: white;
}

@media(max-width:1300px) {
  .couple_img {
    width: 60%
  }
}

@media(max-width:1050px) {
  .coupleprofile_main {
    width: 60%;
  }

}

@media (max-width:900px) {
  .coupleprofile_main {
    width: 70%;
  }

  .coupleprofileCards {
    grid-template-columns: auto;

  }


}

@media(max-width:750px) {
  .coupleprofileCard1 {
    width: 100%;
  }

  .couplenav {
    gap: 38%;
  }

  .couple_img {
    width: 70%
  }

}

@media (max-width:520px) {
  .couplenav {
    padding: 5% 2%;
    /* height:8vh; */
    gap: 30%;
  }

  .couple_img {
    width: 32%;
    height: 19vh;
    margin-bottom: 10px;

  }

  .couple_profile {
    width: 90%;
  }

  .coupledetails_list {
    margin-top: 5%;
  }

  .coupleprofile_main {
    width: 100%;
    transform: none;
  }

  .coupleprofile_bgimage {
    background-image: none;
  }

  .coupleprofile_posts,
  .coupleprofile_Confirmed,
  .coupleprofile_Withdrawn,
  .coupleprofile_TotalCancelled {
    padding: 7%;
  }

  .coupleprofile_jobbtns>button {
    width: 21%;
  }
}

@media (max-width:414px) {
  .couple_img {
    width: 42%;

  }


}