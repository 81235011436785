.screenback{
    background: url("../../Assets/backoverlayimg.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

/* .backoverlay{
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
} */