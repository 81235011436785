.Wback {
  width: 840px;
  height: 610px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  padding: 1.7rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Mcontnt {
  width: 760px;
  height: 599px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dukhdenewaladiv {
  position: absolute;
  top: 3.5%;
  left: 3%;
}

.dukhdenewaladiv > :nth-child(1) {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 1.3px solid #00000041;
  color: #171d1e;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.Mcontnt > :nth-child(1):active {
  transform: scale(0.9);
}

.cnt {
  width: 485px;
  height: 491px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}

/* wedved log */

.cnt > :nth-child(1) {
  width: 100px;
}

.cnt > .texttt > h1 {
  color: #171d1e;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
}

.cnt > .texttt > p {
  color: #7f8c93;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
}

.Rfirstinput {
  width: 370px;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.Rfirstinput > :nth-child(3) {
  transform: scale(1.6);
  color: #7f8182;
  cursor: pointer;
}

.Rfirstinput > :nth-child(3):active {
  transform: scale(1.1);
}

.Rfirstinput > input {
  width: 300px;
  height: 45px;
  background: transparent;
  border: none;
  outline: none;
  padding: 9px;
}

.Rfirstinput > img {
  width: 25px;
}

.resetinputs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.Rbtn {
  /* margin-top: 1rem; */
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Rbtn>p{
  color: red;
}


@media screen and (max-width: 1024px) {
  .Wback {
    width: 840px;
    height: 550px;
    padding: 1rem;
    overflow: hidden;
  }

  /* wedved logo */

  .cnt > :nth-child(1) {
    width: 90px;
  }

  .cnt > .texttt > h1 {
    font-size: 23px;
  }

  .cnt {
    gap: 1rem;
    margin-top: -11px;
  }
}

@media screen and (max-width: 820px) {
  .Wback {
    width: 700px;
  }
}

@media screen and (max-width: 540px) {
  .Wback {
    width: 101vw;
    height: 100vh;
    border-radius: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dukhdenewaladiv {
    position: absolute;
    top: 3%;
    left: 4%;
  }
}

@media screen and (max-width: 414px) {

  .Rfirstinput {
    width: 310.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .err{
    font-size: 14px;
  }

  .Rfirstinput > input {
    width: 270.37px;
    height: 55.17px;
  }

  .Rbtn{
    margin-top: 1.5rem;
  }

  .cnt > .texttt > p{
    font-size: 11px;
  }
  .dukhdenewaladiv {
    position: absolute;
    top: 3%;
    left: 5%;
  }
}
