.Professionnav {
  background-color: #007cc0;
  padding: 2%;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

}

.detailsCard {
  border: 1px solid rgb(185, 183, 183);
  border-radius: 5px;
  padding: 1.5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  float: left;
  margin-left: 2%;
  margin-top: 2%;
  width: 30.7%;
  height: 220px;
}

.headingcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingcard_right>#r {
  cursor: pointer;
}

.headingcard_left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.headingcard_left>h3 {
  font-size: 1.4em;

}

.headingcard_left>p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.756)
}

.headingcard_left>p>span {
  font-weight: 800;
  font-size: 15px;
  color: black;
  margin-right: 10px;
}

.headingcard_right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.imgbtn,
.videobtn {
  margin: 2% 3% 2% 0%;
}

.vendormainarrow {
  position: absolute;
  left: 20px;
  top: 10px;
}

.cardvendordetail {
  display: block;
}

.professions_jobbtns>button {
  margin: 0.7%;
  padding: 1% 3%;
  background-color: #f3f3f3;
  border: 1px solid #d7d7d7;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
}

.professions_images {
  display: flex;
  overflow: auto;
}

.professions_images>img,
.professions_images>div {
  border: 1px solid rgba(0, 0, 0, 0.326);
  width: 21%;
  height: 65px;
  border-radius: 5px;
  margin-top: 10px;
  flex: 0 0 auto;
}

.professions_images>video {
  border: 1px solid rgba(0, 0, 0, 0.326);
  width: 42%;
  height: 65px;
  border-radius: 5px;
  margin-top: 10px;
  flex: 0 0 auto;

}

.professions_images>div {
  background: url('/src/Assets/backoverlayimg.png');
  position: relative;
  background-size: cover;
  text-align: center;

}

.professions_images>div>span {
  color: white;
  position: absolute;
  top: 20%;
  right: 20%;
}

.background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.292);
  position: fixed;
  top: 0%;
}

.sure_popup {
  position: fixed;
  width: 400px;
  top: 45%;
  background-color: #f3f3f3;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.311);
  right: 36%;
  padding: 2%;

}

.sure_popup>p {
  margin: 5%;
}

.sure_popup>button {
  width: 44%;
  padding: 2%;
  background-color: #007cc0;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 5px;
  font-size: 15px;
  cursor: pointer;
}

.sure_popup_delete:hover {
  background-color: crimson;
}

.profession_data {
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 40%;
  right: 45%;
}

@media(max-width:1200px) {

  .sure_popup {
    right: 32%;
  }

  .professions_images>img,
  .professions_images>div {
    width: 21%;
  }
}

@media screen and (max-width:950px) {
  .detailsCard {
    width: 47%;
  }

  .sure_popup {
    right: 28%;
  }
}

@media screen and (max-width: 681px) {
  .detailsCard {
    margin-top: 5%;
    margin-left: 5%;
    width: 90%;
    padding: 3% 2%;
  }


  .professions_images>img,
  .professions_images>div {
    height: 80px;
  }

  .sure_popup {
    right: 21%;
    left: 15%;
  }
}

@media(max-width:540px) {
  .detailsCard {
    margin: 2% 4%;
    width: 92%;
    padding: 4%;
  }


  .Professionnav {
    padding: 5% 2%;
  }

  .sure_popup {
    right: 10%;
    left: 10%;
    width: 80vw;
  }
}

@media(max-width:414px) {

  .professions_images>img,
  .professions_images>div {
    height: 60px;
  }
}