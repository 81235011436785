.leftarrow {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 1.3px solid #00000041;
  color: #171d1e;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.leftarrow:active {
  transform: scale(0.9);
}

.leftarrow>:nth-child(1){
  transform: scale(1.2);
}
