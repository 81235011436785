.login {
  width: 100%;
  text-align: center;
}

.loginpage {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: max-content;
  background: #ffffff;
}

.leftsidelogin {
  width: 50%;
  height: 100vh;
  margin: 0;
}

.leftsidelogin>img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.loginsecondinput> :nth-child(3) {
  transform: scale(1.6);
  color: #7f8182;
  cursor: pointer;
}

.rightsidelogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.9rem;
  width: 50%;
}

.rightsidelogin>img {
  width: 110px;
}

.rightsidelogin>h3 {
  font-size: 2rem;
  line-height: 35px;
  font-weight: 500;
  color: #091009;
}

.logininput {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.loginfirstinput>input {
  width: 92%;
  height: 37px;
  margin: 0.7rem;
  background: transparent;
  outline: none;
  border: none;
  font-style: inherit;
  font-size: inherit;
}

.loginsecondinput>input {
  width: 75%;
  height: 37px;
  margin: 0.7rem;
  background: transparent;
  outline: none;
  border: none;
  font-style: inherit;
  font-size: inherit;

}

.signinbtn>p {
  color: red;

}

.login>div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  margin: 1rem;
}

.loginfirstinput,
.loginsecondinput {
  width: 75%;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px
}

.loginiconimg {
  width: 30px;
}

.rightsidelogin>.login>span {
  color: #00bfd3;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  margin: 10px;
}

.signinbtn>h4>.signupbtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #00bfd3;
  font-weight: 500;
  font-size: 16px;
}

.signinbtn>h4 {
  color: #171d1e;
  font-size: 18px;
  font-weight: 500;
}

.signinbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.login_icon {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

#lg_icon {
  cursor: pointer;
  margin: 10px;
  font-size: 35px;

}

#lg_icon:hover {
  color: #00bfd3;
}

@media screen and (max-width: 1024px) {
  .rightsidelogin>.login>.firstinput {
    width: 390px;
  }

  .rightsidelogin>.login>.secondinput {
    width: 390px;
  }

  .rightsidelogin>.login>div>input {
    width: 350px;
  }

  .loginpage {
    gap: 1.5rem;
  }

  .rightsidelogin {
    gap: 1.1rem;
  }
}

@media screen and (max-width: 912px) {
  .leftsidelogin>img {
    display: none;
  }

  .leftsidelogin {
    flex: 0;
  }

  .rightsidelogin {
    width: 100%
  }

  .rightsidelogin>.login>.firstinput {
    width: 590px;
  }

  .rightsidelogin>.login>.secondinput {
    width: 590px;
  }

  .rightsidelogin>.login>div>input {
    width: 510px;
  }

  .loginpage {
    gap: 0rem;
  }
}

@media screen and (max-width: 540px) {
  .rightsidelogin>.login>.firstinput {
    width: 410px;
  }

  .rightsidelogin>.login>.secondinput {
    width: 410px;
  }

  .rightsidelogin>.login>div>input {
    width: 390px;
  }

  .rightsidelogin>img {
    width: 95px;
  }
}

@media screen and (max-width: 414px) {

  .loginfirstinput,
  .loginsecondinput {
    width: 95%
  }

  .rightsidelogin>.login>.firstinput {
    width: 320.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    margin-left: 1.5rem;
  }

  .rightsidelogin>.login>.secondinput {
    width: 320.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    margin-left: 1.5rem;
  }

  .rightsidelogin>.login>div>input {
    width: 290px;
  }

  .rightsidelogin {
    margin-left: -10px
  }

  .rightsidelogin>h3 {
    font-size: 1.7rem;
  }
}