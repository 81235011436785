.contentdiv {
  padding: 0 4rem;
}

.mapback {
  margin-top: 20px;
}

.vendorview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.vendorbtns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtericon {
  padding-left: 0.7rem;
}

.vendortext>h1 {
  color: #091009;
}

.gridbtn {
  width: 125px;
  height: 40px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.07);
  border-radius: 6px 0px 0px 6px;
  color: #091009;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapbtn {
  width: 125px;
  height: 40px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.07);
  border-radius: 0px 6px 6px 0px;
  color: white;
  outline: none;
  color: black;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vendorbtns>a.active {
  background: #007cc0;
  color: white;
}

.vendorrightside {
  display: flex;
  align-items: center;
  gap: 10px
}

/* Vendor boxs Section */


@media screen and (max-width: 540px) {
  .vendorview {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .contentdiv {
    padding: 0 2rem;
  }
}