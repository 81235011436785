.vmyCard1 {
    /* width: 100%; */
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.201);
    border-radius: 6px;
    display: flex;
    /* gap: 1.4rem; */
    padding: 10px;
    flex-direction: column;
    box-shadow:1px 1px 4px rgba(0, 0, 0, 0.201) ;
  }
  
  .vjobCards {
    display: grid;
    grid-template-columns:32% 32% 32%;
    gap: 1.9%;
    margin-left:1.9%;
    margin-right:1.9%;

}
  
  .vendorconfirmdiv {
    /* width: 397px; */
    /* height: 178px; */
    display: flex;
    gap:1rem;
    flex-direction: column;
  }
  
  .vendorjobtext {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .vendorjobtext > h3 {
    color: #171d1e;
    /* font-weight: 500; */
    font-size:20px;
  }
  
  .vmyjobbtns>button{
    padding:1%;
    width:25%;
    margin-left:5px;
    background-color: #f3f3f3;
    border:1px solid #d7d7d7;
    font-size: 13px;
    font-weight: 600;
  }
  .vendordetails>p{
    color:#7f8c93;
    height:85px;
    overflow: auto;
  }
  .vendorbugetdiv {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-top: 1rem;
    margin-bottom: 8px;
  }
  
  .vendorjobbtn {
    align-self: flex-end;
  }
  
  .vendordiv1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .vendordiv1 > span {
    color: #262f31de;
    font-weight: 400;
    font-size: 14px;
  }
  
  .vendordiv1 > h4 {
    color: #171d1e;
    font-weight: 600;
    font-size: 16px;
  }
  .vendordetails{
    color:rgba(14, 14, 14, 0.756)
  }
  @media screen and (max-width: 1250px){
    .vjobCards{
      grid-template-columns:32% 32% 32%;
      gap: 1.9%;
      margin-left:1.9%;
      margin-right:1.9%;
      
    }
    
  }
  @media screen and (max-width:1010px){
    .vjobCards{
      grid-template-columns:49% 49%;
      gap:2%;
  
    }
    
  }
  
  @media screen and (max-width: 681px){
    .vjobCards{
      grid-template-columns:auto;
      margin:5%;
      gap:2%
    }
  }
  /* @media(max-width:450px){
    .vjobCards{
      margin:5%;
    }
  } */
  
  
  
  