.loader_kovarc {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(241, 5, 5);

}

.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 1.3rem;
  background-color: #ffffff;
  width: 100%;
  height: 71px;
  z-index: 10000;
}

.responsive_toggle {
  display: flex;

}

.responsive_toggle>li {
  list-style-type: none;
}

.nav_img {
  width: 35%;
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.menulist {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  user-select: none;
  width: 77%;
}

.menutoggle {
  display: none;
}

.menulist>li {
  margin: 0 1.3rem;
  list-style: none;
  color: #091011;

}

.menulist>li>a {
  text-decoration: none;
  color: #373d3d;
  font-weight: bold;
}

.menulist>li>a:hover {
  cursor: pointer;
  color: #0dc3dc;
  border-bottom: 3px solid #0dc3dc;
  padding-bottom: 1.5rem;
}

.menulist>li>a:active {
  color: #0dc3dc;
  border-bottom: 3px solid #0dc3dc;

}

.menulist>li>a.active {
  color: #0dc3dc;
  border-bottom: 3px solid #0dc3dc;
}

.btn {
  width: 120px;
  height: 45px;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #091011;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3 ease-out;
}

.btn:hover {
  background-color: #0dc3dc;
}

.btn:active {
  transform: scale(0.9);
}

/* .btndiv{
    display: flex;
    align-items: center;
} */

.img-section>div>img {
  width: 100%;
  height: 700px;
  background-position: 100%;
  background-size: cover;
}

.findvendor {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: center;
  flex-direction: column;
  top: 40%;
}

.findvendor>h1 {
  color: #ffffff;
  text-align: center;
  font-size: 3.5rem;
  text-transform: capitalize;
}

.findvendor>span {
  color: #ffffff;
  text-align: center;
  width: 40%;
}

.toggle {
  display: none;
}

/* media qeury */

@media screen and (max-width: 1280px) {
  .findvendor {
    top: 44%;
  }

  .findvendor>span {
    width: 50%
  }

}

@media screen and (max-width: 1024px) {

  .menulist>li>a {
    font-size: 0.8rem;
    flex: 10;
  }

  .btn {
    width: 100px;
    height: 45px;
  }


  .menulist>li {
    margin: 0 1.1rem;
  }

  .findvendor>h1 {
    font-size: 3rem;
  }

  .findvendor {
    top: 25%;
  }


  .img-section>div>img {
    width: 100%;
    height: 450px;
  }
}

@media screen and (max-width: 912px) {
  .menulist>li>a {
    font-size: 0.6rem;

  }

  .btndiv {
    margin-left: 1rem;
  }

  .findvendor {
    top: 20%;
  }

  .findvendor>span {
    width: 60%;
  }
}

@media screen and (max-width: 820px) {
  .menulist {
    display: none;

  }

  .btn:hover {
    background-color: #0dc3dc;
  }

  .btn:active {
    transform: scale(0.9);
  }

  .main-nav {
    position: relative;

  }




  .menulist-show {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 1.5rem;
    background-color: #0dc3dc;
    color: #ffffff;
    z-index: 100;
    display: block;
  }

  .menulist-show>li>a:hover {
    color: #7D9295;
  }

  .menulist-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    list-style: none;
  }

  .menulist-show>li>a {
    font-size: 1rem;
    padding: 10px 20px;
  }



  .toggle {
    display: block;
    z-index: 10000;
  }

  .toggle>:nth-child(1) {
    font-size: 3rem;
    cursor: pointer;
  }

  .findvendor {
    top: 17%;
  }

  .findvendor>span {
    width: 70%;
  }
}



@media screen and (max-width: 768px) {

  .menutoggle>* {
    font-size: 10px;
  }

  .main-nav>.logo>a>img {
    width: 38%;
  }

  .btn {
    width: 102px;
    height: 41px;
    font-size: 17px;
    margin-left: -1rem;
  }

  .btn:hover {
    background-color: #373d3d;
  }

  .findvendor>h1 {
    font-size: 2.5rem;
  }

  .findvendor {
    top: 23%;
    z-index: 3;
  }

  .findvendor>span {
    font-size: 15px;
    margin-top: -10px;

  }
}

@media screen and (max-width: 540px) {
  .findvendor>h1 {
    font-size: 1.5rem;
  }

  .menulist-show {
    gap: 1.5rem;
    font-size: 1rem;
  }

  .findvendor {
    top: 11%;
    z-index: 3;
  }

  .findvendor>span {
    font-size: 10px;
    width: 90%
  }

  .img-section>div>img {
    width: 100%;
    height: auto;
  }

}

@media screen and (max-width: 414px) {
  .findvendor>h1 {
    font-size: 1.4rem;
  }

  .menulist-show {
    height: 90vh;
    font-size: 1.2rem;
    gap: 1.8rem;
  }

  .findbtn {
    margin-top: -8px;
  }

  .img-section>div>img {
    width: 100%;
    height: 200px;
  }

  .main-nav>.logo>a>img {
    width: 31%;
  }

  .findvendor>span {
    font-size: 10px;
  }

  .findvendor {
    top: 13%;
    z-index: 3;
  }

}

@media screen and (max-width: 375px) {
  .findvendor {
    top: 13%;
  }


}

@media screen and (max-width: 360px) {
  .findvendor {
    top: 12.5%;
  }
}