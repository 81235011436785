

.vendorbox {
  /* width: max-content; */
  /* height: max-content; */
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  box-shadow:1px 1px 4px rgba(0, 0, 0, 0.201) ;
  
}
.searchicon {
  width: 95%;
  height: 40px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin:20px;

  
}

.searchicon > input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-style: inherit;
  padding-left: 8px;
}
.searchinput{
  display: block;
  width:100%;
  margin-left:auto;
  margin-right: auto;
}

.vendorsprofile{
  display: grid;
  /* grid-template-columns: repeat(4,1fr); */
  grid-template-columns: 23.7% 23.7% 23.7% 23.7%;
  gap: 1.8%;
  margin-top: 2rem;
  margin-left:2%;
  margin-right:2%;
 flex: 1;
  /* z-index: 1; */
  }
  .vendor-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem; /* Add extra margin to create space for the nextslider */
      }
      
  .boxcntnt {
  /* width: 397px; */
  /* height: 247px; */
}

.location> h3{
  color: #171d1e;
  font-size: 20px;
  font-weight: 600;
}
.vname>img{
  width:80px;
  height:80px;
  border-radius: 50px;
}

.vname {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content:flex-start;
  gap: 1rem;
  width: 100%;
}


.miles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.miles > span {
  color: #02020282;
  font-size: 14px;
  font-weight: 400;
}

.location {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.lastbtn {
  align-self: flex-end;
}

.singerbtn > button {
  padding: 2%;
  margin-right:3%;
  margin:2%;
 background: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}
/* .singerbtn {
  display: flex;
  gap: 5px;
} */

.dancerbtn > :nth-child(2) {
  width: 119px;
  height: 26px;
  background: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}
.dancerbtn > :nth-child(1) {
  width: 65px;
  height: 26px;
  background: #f3f3f3;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
}

.dancerbtn {
  display: flex;
  gap: 5px;
}

.singer,
.dancer2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.singer > h3,
.dancer2 > h3 {
  color: #171d1e;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.vendorcardbtn>button {
  align-self: flex-end;
  width:100%;
  background: #00bfd3;
  border-radius: 5px;
  padding:3%;
  font-size:15px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  z-index:1;

}


@media screen and (max-width: 1290px){
  .vendorsprofile{
    grid-template-columns:32% 32% 32%;
    gap: 1.9%;
    margin-left:1.9%;
    margin-right:1.9%;
}
  
}
@media screen and (max-width:1010px){
  .vendorsprofile{
    grid-template-columns:49% 49%;
    gap:2%;
}
  
}

@media screen and (max-width: 681px){
  .vendorsprofile{
    grid-template-columns:auto;
    margin-left:5%;
    margin-right:5%;
  
  }
}
@media(max-width:400px){
  .vendorsprofile{
    margin-left:1%;
  margin-right:1%;
  
  }
}

 

