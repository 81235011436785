.Jobdetails_nav {
    background-color: #007cc0;
    padding: 2%;
    color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

}

.jobdetails_content {
    display: flex;
    flex-direction: column;
}

.jobdetails_details {
    padding: 5%;
    width: 50%;
}

.jobdetails_main {
    width: 100%;
    margin: 2% 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #ecf0f2;
}

/* .div1>h4{
    font-weight: bold;
} */
.jobdetails_details>p {
    margin-top: 7%;
}

.jobdetails_img {
    /* margin:5%; */
    display: block;
}

.jobdetails_img>img {
    margin: 10% 2%;
    border-radius: 25px;
    width: 100%;
    height: 80%;
}

.jobdetails_text {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.jobdetails_text>h3 {
    margin-bottom: 10px;
}

.open_status {
    padding: 7px;
    height: 26px;
    color: white;
    background-color: #00bfd3;
    font-weight: 400;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel_status {
    padding: 7px;
    height: 26px;
    color: white;
    background-color: #d44545;
    font-weight: 400;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdrawn_status {
    padding: 7px;
    height: 26px;
    color: white;
    background-color: #df9c13;
    font-weight: 400;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm_status {
    padding: 7px;
    height: 26px;
    color: white;
    background-color: #007cc0;
    font-weight: 400;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expire_status {
    padding: 7px;
    height: 26px;
    color: white;
    background-color: #4b5759;
    font-weight: 400;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.complete_status {
    padding: 7px;
    height: 26px;
    color: white;
    background-color: #1ea95f;
    font-weight: 400;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.withdrawn_btn {
    width: 100%;
    font-size: 0.9rem;
    height: 40px;
    background: #00bfd3;
    border-radius: 6px;
    color: white;
    border: none;
    cursor: pointer;
    display: block;
    margin: 5% auto;

}

.cancel_btn {
    width: 100%;
    font-size: 0.9rem;
    height: 40px;
    background: #d44545;
    border-radius: 6px;
    color: white;
    border: none;
    cursor: pointer;
    display: block;
    margin: 5% auto;
}

.jobdetails_list {
    border-left: 1px solid rgba(132, 131, 131, 0.273);
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    gap: 0%
}

.jobdetails_list>li {
    width: 33%;
    text-align: center;
}

.jobdetails_list>li a {
    color: #373d3d;
    text-decoration: none;
    display: block;
    padding-bottom: 6%;
    border-bottom: 1px solid #373d3d;


}

.jobdetails_list>li>a.active {
    color: #0dc3dc;
    border-bottom: 1px solid #0dc3dc;
}


@media(max-width:800px) {
    .Jobdetails_nav {
        height: 50px;
        padding: 5% 2%;
    }

    .jobdetails_content {
        flex-direction: column;
    }

    .jobdetails_img {
        display: none;
    }

    .jobdetails_main {
        margin: 5% 0%;
        background-color: white;
    }

    .jobdetails_details {
        width: 100%;
    }
}