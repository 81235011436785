.btn1 {
    background-color: #0dc3d7;
    width: 175px;
    height: 45px;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 45px;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
    color: #FFFFFF;
    border-radius: 4px;
    text-transform: capitalize;
}

.btn1:active {
    transform: scale(0.9);
}


@media screen and (max-width: 540px) {
    .btn1 {
        width: 150px;
        height: 40px;
    }
}

@media screen and (max-width: 414px) {
    .btn1 {
        width: 120px;
        font-size: 11px;
        height: 30px;

    }
}