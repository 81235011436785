.cards_nav{
  background-color:#007cc0;
  padding: 2%;
  color:white;
  width:100%;
  text-align: center;
  display: flex;
  justify-content:flex-start;
  gap:45%;
  position:relative;
}
.card_div {
  width:40%;
  margin:0 2%;
  padding:2%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: #f1f1f1;
  min-height: 91vh;
  max-height: max-content;
  box-shadow: 0.5px 0.5px 5px #09100989;
}
.card_list{
  display: flex;
  flex-direction: column;
  gap: 1rem;

}
.card_number{
  width: 100%;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.card_number>h4 {
  width: 70%;
  padding: 10px;
  color:rgba(31, 31, 31, 0.604)

}

.dotimg {
  padding-left: 15px;
  padding-right: 8px;
  cursor: pointer;
}


.brandimg{
  width:40px;
  height:30px
}

.checkboxround > div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.dabitcard {
  display: flex;
  justify-content: space-between;
}

.dabitcard > :nth-child(1) {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.addcarddiv {
  height: 177px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin:2%;
  margin-top:10%;
}

.addcarddiv>h3{
  color: #091009;
}

.addcarddiv>.dabitcard>div>h4{
  color: #091009;
}

.addcbtn > button {
  width: 79px;
  height: 32px;
  background: transparent;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  cursor: pointer;
  background: transparent;
  outline: none;
}

.addcbtn > :active {
  transform: scale(0.9);
}

.cards_button{
  cursor:pointer;
  width:90%;
  padding:10px;
  background-color:#00BFD3;
  color:#ffffff;
  border:none;
  font-size: 20px;
  margin:3% auto;
  display:block;
  border-radius: 5px;

}
.delete_modal{
  padding:2%;
  font-size: 13px;
  background-color: rgb(230, 228, 228);
  box-shadow: 0.2px 0.2px 2px #09100912;
  width:100px;
  border-radius:5px;
}
@media screen and (max-width: 950px) {
  .card_div{
    width:50%
  }
}

@media screen and (max-width: 700px) {
  .card_div{
    width:60%
  }

}
@media screen and (max-width: 540px) {
  .card_div{
    width:90%
  }
  .cards_nav{
    padding:5% 2%;
    /* height:50px; */
    gap:35%;
  }

}

@media screen and (max-width: 414px) {
  .card_div{
    width:100%
  }
  .cards_nav{
    gap:30%;
  }

  .dabitcard {
    display: flex;
    justify-content: space-evenly;
  }

  .addcarddiv > h3 {
    margin-left: 3.5rem;
  }

  #khainiiii {
    margin-left: -28px;
  }
}


