.setting_main {
    display: flex;
    right: 0;
    top: 17%;
    position: absolute;
    width: 100%;
    z-index: -1;

}

/* .test{
    display: flex;
} */
.setting_header {
    background-image: linear-gradient(to right, rgb(0 124 192), rgb(13 195 215));
    color: white;
    /* box-shadow:2px 2px 3px #024a71; */
    padding-top: 1.5%;
    width: 100%;


}

.setting_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40%;
    padding-bottom: 2%;

}

.setting_titlenav {
    background-color: #007cc0;
    color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 1.5%;
    gap: 41%;

}

.setting_list>img {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 10%;
    left: 7%
}

/* .setting_bar{
    background-color:#ffffff;
    box-shadow:2px 2px 5px rgba(0, 0, 0, 0.664);
    width:250px;
    height:100vh;
    top:8.9%;
    padding-top:2%;
    position: fixed}
 */
/* box-shadow:3px 0px 5px rgb(208, 206, 206) ;
    width:240px;
    background-color: rgb(245, 242, 242);
    height: 100vh;
    display: flex;
    flex-direction: column;
    
    transition:1ms;
    top:54px; */
.setting_listhide {
    display: none;
}

.setting_list {
    font-size: 1rem;
    list-style-type: none;
    display: flex;
    justify-content: space-evenly;
    /* border-bottom:1px solid #f3f3f3; */
    /* padding: 2% 0%; */
    background-color: rgb(248 247 247);
}

.setting_list>li>a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    text-decoration: none;
    padding: 20px 0;
    margin: 0 20px;
}

.setting_list>li>a:hover {
    border-bottom: 1px solid #00bfd3;
    color: #00bfd3
}

.setting_list>li>a.active {
    color: #00bfd3;
    border-bottom: 2px solid #00bfd3;

}

/* .setting_list>li >a:hover {
  cursor: pointer;
  background-color:rgba(58, 53, 65, 0.04);
  box-shadow:1px 1px 2px #353535;
  border-radius: 0px 25px 25px 0px;
  font-size:1.1rem;
  border-left: 3px solid #070707;

}
.setting_list>li >a.active{
  background-image:linear-gradient(to right, #5ab6e7,#1278af);
  box-shadow:1px 1px 2px #353535;
  border-radius: 0px 25px 25px 0px;
  color:#ffffff;
  font-size:1.1rem;
} */
.term_data,
.notification_data,
.About_data,
.change_data {
    width: 100%;
    /* background-color: transparent; */
    padding: 2%;
    word-wrap: break-word;
}

.notification_list {
    font-size: 1.3rem;
    list-style-type: none;
}

.notification_list>li {
    /* margin:2% 5%; */
    padding: 2% 2%;
    border-bottom: 1px solid rgba(177, 173, 173, 0.791);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.notification_data>.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.5rem;
    color: #dfe7ec;
}

#on_nf_toggle {
    color: #007cc0;
    font-size: 3rem;
    cursor: pointer;
    background-color: white;
}

#off_nf_toggle {
    color: #868d90;
    font-size: 3rem;
    cursor: pointer;

}

#arw {
    display: none;
}

.chjek {
    width: fit-content
}

#backbtn {
    display: block;
}

@media(max-width:850px) {
    .setting_titlenav {
        gap: 35%;
        padding: 2%;


    }
}

@media(max-width:600px) {
    .setting_titlenav {
        gap: 33%;
        padding: 3% 2%;


    }

    .setting_list {
        flex-direction: column;
        border-radius: 0px;
        background-color: white;
        font-size: 15px;
        padding-bottom: 30vh;

    }

    .setting_main {
        top: 7%;
    }

    .setting_title {
        padding-bottom: 5%;
        gap: 32%;
        padding-left: 10px
    }

    .setting_list>li>a:hover,
    .setting_list>li>a.active {
        border-radius: 0px;
        background-color: none;
        box-shadow: none;
        border-bottom: 1px solid #353535;
        background-image: none;
        color: black;
    }

    .setting_list>li>a {
        margin: 0% 5%;
        border-bottom: 1px solid #353535;
        color: black;
        justify-content: space-between;
    }

    .setting_header {
        background: #007cc0;
        padding-top: 5%;
    }

    .setting_title>h3 {
        font-size: 20px;
        font-weight: 500 !important;

    }

    #arw {
        display: block
    }

    #backbtn {
        display: none;
    }

    .chek {
        width: 85%;
        display: flex;
        justify-content: space-between;
    }

    .backarw_btn {
        padding: 2%;
        background-color: #00bfd3;
        border-radius: 5px;
        width: 27%;
        display: flex;
        gap: 10%;
        align-items: center;
        margin: 5%;
    }

}

@media(max-width:500px) {
    .setting_titlenav {
        gap: 25%;
        padding: 5% 2%;
        font-size: 12px;
        align-items: center;
    }

}