.assign_button {
  width: 40%;
  font-size: 0.9rem;
  height: 40px;
  background: #ffffff;
  border: 1px solid #00bfd3;
  border-radius: 6px;
  color: #00bfd3;
  cursor: pointer;
  float: left;
}

.assign_button:hover {
  background: #00bfd3;
  border: none;
  color: white;
}

.assign_btngroup {
  display: flex;
  justify-content: space-around;
}

.assign {
  margin-top: 250px;
  z-index: 1000;
  width: 50%;
  padding: 1rem 3rem;
  text-align: center;
  height: 350px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.55);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.assign_main {
  Width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5rem 1.5rem;

}

.assign_bg {
  width: 100vw;

}

.assign_b {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0%;
  height: 100vh;
}

@media(max-width:950px) {
  .assign_main {
    padding: 0%;
  }

  .assign_main>h1 {
    font-size: 25px;
  }

}

@media(max-width:700px) {
  .assign {
    width: 60%;

  }
}

@media(max-width:500px) {
  .assign {
    width: 90%;
    padding: 5px 10px;
  }
}