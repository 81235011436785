.error {
  text-align: center;
  color: red;
  margin: 10px;
}

.addcard_nav {
  background-color: #007cc0;
  padding: 1.5%;
  color: white;
  width: 100%;
  text-align: center;

}

/* .addcard_nav>img{
    width:50%
} */

.addcard_header {
  display: flex;
  justify-content: flex-start;
  gap: 45%;
  position: relative;
  margin: 2%;
}

.addcard_details {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.details_input {
  width: 100%;
  /* height: 55px; */
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.192) !important;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
  padding: 2% 3%
}

.details_input>input {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: 1.2rem;
}

.details_input>img {
  width: 25px;
  margin-left: 8px;
}

.details2_input {
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 3%
}

/* .details2_input > input {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding-left: 10px;
    font-size: 1.2rem;
  } */

.addcard_date {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;


}

.addcard_date>img {
  width: 25px;
  margin-left: 8px;
}

.date_field {
  display: flex;
  align-items: center;
  justify-content: center;

}

.date_input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.192) !important;
  width: 35%;
  background: #ffffff;
  padding: 1% 3%;
  margin: 1% 4%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.date_input>select {
  width: 100%;
  font-size: 17px;
  border: none;
  outline: none
}

.date_input>option {
  margin: 2% !important;
}

.date_input>input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
}

#help {
  cursor: pointer
}

.addcard_button {
  cursor: pointer;
  width: 50%;
  padding: 10px;
  background-color: #00BFD3;
  color: #ffffff;
  border: none;
  font-size: 20px;
  margin: 3% auto;
  display: block;
  border-radius: 5px;

}

.ElementsApp {
  display: flex !important;
}

@media screen and (max-width: 950px) {
  .details_input {
    padding: 3%;
  }

  .details2_input {
    padding: 3%;
  }
}

@media screen and (max-width: 600px) {
  .details_input {
    padding: 4%;
  }

  .details2_input {
    padding: 4%;
  }

  .addcard_button {
    width: 90%;
    margin-top: 15%;

  }


}

@media screen and (max-width: 540px) {
  .details_input {
    padding: 5.5%;
  }

  .details2_input {
    padding: 5.5%;
  }

  .addcard_header {
    padding: 5% 2%;
    /* height:50px; */
    gap: 35%;
  }




}

@media screen and (max-width: 414px) {
  .details_input {
    padding: 7%;
  }

  .details2_input {
    padding: 7%;
  }

  .addcard_header {
    gap: 30%;
  }

  .addcard_button {
    margin-top: 30%;

  }

}