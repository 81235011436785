.Rbidnav {
    background-color: #007cc0;
    padding: 2%;
    color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 45%;
    position: relative;
}

.Rbidmain {
    margin-top: 15px;
}

@media(max-width:540px) {
    .Rbidnav {
        padding: 5% 2%;
        /* height:50px; */
        gap: 35%;
    }
}

@media screen and (max-width: 414px) {
    .Rbidnav {
        gap: 30%;
    }
}