.ratting_div{
    display: flex; 
    border: 1px solid #c9c7c7; 
    margin-left:2%;
    margin-top:2%;
    margin-bottom: 2%;
    padding:2%;
    width:45%;
    float:left;
    display: flex;
    justify-content:flex-start;
    gap:2rem;


}
.ratting_div>img{
width:50px;
height:50px;
border-radius: 50%;
}
.ratting_right{
    display: flex;
    flex-direction: column;
    width:70%
}
.ratting_right>p{
    color:#040404a3
}
.rating_name>h3{
    margin-bottom: 5px;
}
.rating_name>p{
    font-size: 14px;
}
.rating_updiv{
    display: flex;
    margin-bottom:10px;
    justify-content: space-between;
}
@media(max-width:650px){
    .ratting_div{
        width:80%;
        margin:3% 10%;
    }
}
@media(max-width:500px){
    .ratting_div{
        width:95%;
        margin:2%;
    }
}
