#Lbtn{
  background-color: #00bfd3;
  width: 176px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 15px 30px rgba(5, 166, 183, 0.27);
  border-radius: 6px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

#Lbtn:active{
  transform: scale(0.9);
}

@media screen and (max-width: 414px) {
  #Lbtn {
    width: 315px;
  }

}
