.createjob_nav {
    background-color: #007cc0;
    padding: 2%;
    color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 45%;
    position: relative;

}

.createjob_header {
    height: 250px;
    padding: 2%;
    display: block;
    background-image: linear-gradient(to right, rgb(0 124 192), rgb(13 195 215));
}

.createjob_header>h1 {
    margin-top: 6%;
    color: white;
}

.createjob_side {
    width: 40%;
    padding: 2%;
    display: block;
}

.createjob_main {
    box-shadow: 2px 2px 5px rgb(129, 128, 128);
    width: 55%;
    position: absolute;
    right: 6%;
    top: 25%;
    padding: 3%;
    padding-bottom: 0%;
    border-top: 25px solid rgba(23, 23, 23, 0.93);
    background-color: #ffffff;
    /* color:white; */
    border-radius: 15px;
}

.form_button {
    width: 100%;
    font-size: 0.9rem;
    height: 40px;
    background: #00bfd3;
    border-radius: 6px;
    color: white;
    border: none;
    cursor: pointer;
    display: block;
    margin: 5% auto;

}

.cardselected {
    margin-top: 30px;
    width: 100%
}

.createjob_formname>label,
.cardselected>label {
    font-size: 1.2rem;
    font-weight: bold;
}

.createjob_formname {
    border-bottom: 1px solid rgb(178, 177, 177);
    margin-top: 7%;
    margin-bottom: 2%;
}

.form_data {
    width: 100%;
    margin-top: 2%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1.1rem;
    color: #2a2929da;
}

.jobinput {
    width: 100% !important;
    margin: 0 !important;
}

.jobinput_label {
    font-size: 1.2rem !important;
    font-weight: bold !important;
    color: black !important;
}

.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
    border: none !important;
}

.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
    border: none !important;
}

.cardlist {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.selectcard>input {
    width: 18px;
    height: 18px;
    margin: 5px;
}

.card_main {
    width: 230px;
    height: 130px;
    background-image: url(../../../Assets/card_bg@2x.png);
    background-size: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    color: #ffffff;
    box-shadow: 1px 1px 5px #0b0b0b87;
    flex: 0 0 auto;
    text-align: left;

}

::-webkit-scrollbar {
    width: 5px;
}


.card_main>h4 {
    width: 100%;
}

.cardbrandimg {
    width: 30px;
    height: 20px;
}

.card_mainup {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.carddate {
    width: 100%
}

.carddate>p {
    font-size: 15px;
    color: #ffffffcf;
    margin-bottom: 6px
}

@media(max-width:500px) {

    .createjob_formname>label,
    .cardselected>label {
        font-weight: 400;

    }

    .createjob_nav {
        padding: 5% 2%;
        height: 50px;
        gap: 30%;
    }

    .createjob_main {
        /* margin:5%; */
        right: 0%;
        padding: 0% 5%;
        width: 100%;
        background-color: white;
        position: relative;
        box-shadow: none;
        border-top: none;

    }

    .createjob_formname {
        margin-top: 12%;
        margin-bottom: 2%;

    }

    .form_data {
        margin-top: 8%;
    }

    .createjob_header {
        display: none;
    }

    .createjob_side {
        display: none;
    }
}