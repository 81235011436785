.backprofile{
  width:75%;
}


.Cprofilebackw {
  width: 100%;
  height: 574px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.maininputsDiv {
  display: flex;
  gap: 0.7rem;
  align-items: flex-end;
  width:92%;
}

.Cprofilectn {
  width: 950px;
  height: 494px;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.mainarrow {
  position: absolute;
  left: 5px;
}

.cprotext > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #171d1e;
}

.cprotext > p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #171d1e;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.Clogo {
  width: 100px;
  margin-left: 3rem;
}

.firstproinput,.css-1xhypcz-MuiStack-root{ 
   width: 33%;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.MuiFormControl-root .MuiTextField-root .css-1u3bzj6-MuiFormControl-root-MuiTextField-root
{
    width:90%
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
  width:100%;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
}
.firstproinput > input,
.datalocation {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 10px;
}

.firstproinput > img {
  width: 25px;
  margin-left: 8px;
}
.textarea{
  width:92%;
}
.textarea > input {
  width: 100%;
  height: 128px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  outline: none;
  padding: 13px;
  position: relative;
}

.textarea > input::placeholder {
  position: absolute;
}

.Cprofilebtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.skipbtn > button {
  width: 165px;
  height: 50px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  text-align: center;
  color: #171d1e;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.skipbtn > button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 1024px) {
  .Cprofilebackw {
    /* width: 900px;
    height: 520px; */
  }

  .textarea > input {
    /* width: 850px; */
  }

  .firstproinput {
    /* width: 276px; */
  }

  .maininputsDiv,
  .textarea {
    display: flex;
    justify-content: center;
  }

  .mainarrow {
    margin-left: 1rem;
    margin-top: 0.8rem;
  }
}

@media screen and (max-width: 912px) {
  .backprofile{
    width:80%;
  }
  .Cprofilebackw {
    /* width: 710px;
    height: 520px; */
  }

  .textarea > input {
    /* width: 650px; */
  }

  .firstproinput {
    /* width: 210px; */
  }
}
@media screen and (max-width: 650px){
  .backprofile{
    width:90%;
  }

}
@media screen and (max-width: 540px) {
  .backprofile{
    width:100%;
    height:100vh;
  }

  .Cprofilebackw {
    height:100vh;
    border-radius: 0px;

  }
  .textarea > input {
    /* width: 450px;
    height: 90px; */
  }

  .firstproinput {
    width:100%;
  }

  .maininputsDiv {
    flex-direction: column;
  }

  .firstproinput > input {
    /* width: 400px;
    height: 50px; */
  }

  .Cprofilectn {
    align-self: flex-start;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width: 414px) {
  .textarea > input {
    /* width: 320.37px;
    height: 75.17px; */
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .Clogo {
    width: 80px;
    margin-left: 3.6rem
  }

  .firstproinput > input {
    /* width: 300px; */
    /* height: 50px; */
  }
  .firstproinput {
    /* width: 320.37px;
     height: 55.17px; */
     background: transparent;
     border-top: 0px;
     border-left: 0px;
     border-right: 0px;
     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     border-radius: 0px;
  }

    .Cprofilebtns{
      flex-direction: column;

    }

    .skipbtn > button {
      width: 315px;
    }

    .mainarrow {
      margin-top: -4rem;
    }

    .Cprofilectn{
      margin-top: 6rem
    }
  
}

@media screen and (max-width: 375px){
  .Clogo {
    margin-top: -5rem;
    margin-left: 3.9rem;
  }

  .Cprofilectn{
      gap: 0.5rem
  }
}
