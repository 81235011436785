.mainitems {
  width: 908px;
  height: 660px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainitem {
  width: 788px;
  height: 580.26px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.backarrow_option {
  position: absolute;
  left: 1.5rem;
  top: 1rem;


}

.mybutton {
  width: 180px;
  background-color: rgb(59, 89, 152);
  padding: 12px;
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
  border-radius: 2px;
  height: 42px
}

/* logo */
.main_logo {
  width: 100px;
}

.mainitemtext {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 788px;
  height: 219px;
  gap: 1rem;
}

.mainitemtext>p {
  padding-bottom: 1rem;
  color: #171D1E;
  font-weight: 400;
  font-size: 18px;
}

.mainitemtext>p>span {
  color: #00BFD3;
  cursor: pointer;
}

.sociallinkss>a>img {
  width: 210px;
}

.sociallinkss>button,
.sociallinkss>div>button {
  width: 210px !important;
  font-size: 15px !important;
  margin: 0px !important;
  height: 42px !important;
}

/* .sociallinkss>div>button{
  width: 210px !important;
font-size:  15px !important;
margin:0px !important;
height: 42px !important;

} */
.sociallinkss>button>div>div>svg>g>path {
  fill: #ffffff !important
}

.sociallinkss {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;

}


@media screen and (max-width: 1024px) {
  .mainitems {
    width: 800px;
    height: 550px;
  }

  .main_grp {
    width: 400px;
  }

  .mainitem {
    justify-content: center;

  }

  .sociallinkss>a>img,
  .sociallinkss>button,
  .sociallinkss>div>button {
    width: 180px !important;
  }


}


@media screen and (max-width: 820px) {
  .mainitems {
    width: 720px;
  }

  .sociallinkss>a>img {
    height: 42px
  }


}

@media screen and (max-width: 540px) {

  .mainitems {
    width: 101vw;
    height: 100vh;
    border-radius: 0px;
  }

  .sociallinkss {
    flex-direction: column;

  }

  .mainitem {
    justify-content: flex-start;
    gap: 2rem;
  }

  .mainitemtext {
    margin-top: 4rem;
  }

  .mainitemtext>h2 {
    font-size: 1.2rem;

  }

  .mainitemtext>:nth-child(2) {
    font-size: 12px;
    text-align: justify;
  }

  .mainitemtext>p {
    font-size: 15px;
  }

  .main_grp {
    width: 300px;
  }

  /* .sociallinkss >a> img {
    width: 250px;
  } */

}

@media screen and (max-width: 414px) {
  .mainitemtext>h2 {
    font-size: 1.1rem;
  }

  .main_grp {
    font-size: 12px;
    text-align: justify;
  }
}