.chat_title {
    background-color: #007cc0;
    padding: 2%;
    color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 45%;
    position: relative;

}

.container_fluid {
    padding: 3rem 0.75rem;
    background-color: rgb(238, 238, 238);
}

.chatbox {
    justify-content: center;
    display: flex;
}

.chatboxi {
    width: 70%;
    margin: 2% 6%;
}

.chatcard {
    box-shadow: 0 2px 15px -3px rgba(0, 0, 0, .07), 0 10px 20px -2px rgba(0, 0, 0, .04);
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .125);
    background-color: #fff;
    word-wrap: break-word;
    border-radius: 15px;

}

.chatheader {
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #f5f5f5;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.chatheader:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
}

.chatheader>h5 {
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: capitalize;
}

.chatheader>img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
}

.overflow {
    overflow-y: scroll;
    height: 65vh;
    padding: 1.5rem;
}

.mychat {
    padding-top: 0.25rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.mychat>div>p {
    padding: 7px 22px;
    font-size: 1.1rem;
    background-color: #007cc0;
    color: white;
    border-radius: 1.5rem 0rem 1.5rem 1.5rem;
    font-family: Be Vietnam Bold;
}

.chat_time {
    background-color: #fff !important;
    font-size: 0.8rem !important;
    padding: 5px !important;
    color: #B3B3B3 !important;
}

.senderchat {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding-top: 0.25rem;
    margin-bottom: 0.5rem;


}

.senderchat>div>p {
    padding: 0.8rem 1.5rem;
    font-size: 1.1rem;
    border-radius: 0.3rem;
    background-color: #F2F2F6;
    font-family: Be Vietnam Bold;
    border-radius: 0rem 1.5rem 1.5rem 1.5rem;
    color: #171D1E
}

.chatfooter {
    background-color: rgba(255, 255, 255, 0);
    border-bottom: 2px solid #f5f5f5;
    padding: .75rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.chat_form {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
}

.chat_input {
    border-bottom: 1px solid #E4E4E4;
    width: 100%;
    display: flex;
    align-items: flex-end;
}

.chat_input>input {
    border: none;
    outline: none;
}

.typmsg {
    line-height: 2.15;
    border-radius: 0.25rem;
    padding: .5rem 1rem;
    display: block;
    width: 100%;
    border: 1px solid #bdbdbd;
}

.sendbtn {
    border: 1px solid white;
    background-color: white;
}

.sendbtn>a {
    color: #007cc0;
    margin: 10px
}

/* .sending{
    position:fixed;
    bottom: 8%;
    width:80%;
    overflow: hidden;
} */
@media(max-width:1400px) {
    .chatboxi {
        margin: 3% 6%
    }
}

@media(max-width:1170px) {
    .chatboxi {
        margin: 4% 6%
    }
}

@media(max-width:1000px) {
    .chatboxi {
        margin: 6%
    }
}

@media(max-width:650px) {
    .chatboxi {
        width: 100%;
        margin: 0;
    }

    .senderchat>div>p {
        font-size: 1rem;
    }

    .mychat>div>p {
        font-size: 1rem;

    }

    .overflow {
        height: 73vh;

    }
}

@media (max-width: 540px) {
    .chat_title {
        padding: 5% 2%;
        gap: 35%;
    }

}

@media(max-width:414px) {
    .chat_title {
        gap: 35%;
    }
}