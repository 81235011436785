.mainC {
  width: 840px;
  height: max-content;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 760px;
  height: max-content;
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 5px;
}

.cmainCnt>h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #171d1e;
}

/* logo */
.content> :nth-child(2) {
  width: 100px;
}

/* forgotgirl */

.content> :nth-child(4) {
  width: 238px;
}

#backicon {
  position: absolute;
  z-index: 1;
  margin: 15px 0;
}

/* p tag */

.content> :nth-child(5) {
  color: #7f8c93;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 10px;

}

.fE {
  width: 370px;
  height: 55px;
  background: #f2f5f7;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fE>input {
  width: 300px;
  height: 50px;
  border: none;
  background: transparent;
  outline: none;
  font: inherit;
  padding: 10px;
}

.fE>img {
  width: 25px;
}



.backabtn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  left: 1.5rem;
  top: 0.3rem;

}

.backabtn:active {
  transform: scale(0.9);
}


#sbtn {
  margin-top: 0.7rem;
}

@media screen and (max-width: 1024px) {
  .mainC {
    width: 840px;
    position: relative;
  }

  .content> :nth-child(2) {
    width: 80px;
  }

  .content>h2 {
    font-size: 20px;

  }

  /* p tag */

  .content> :nth-child(5) {
    font-size: 12px;
    padding-bottom: 2px;

  }

  /* forgot girl pic */
  .content> :nth-child(4) {
    width: 170px;
  }


  #sbtn {
    margin-top: 0.5rem;
  }

  .backabtn {
    position: absolute;
    top: 0.3rem;
    left: 2rem;

  }

}

@media screen and (max-width: 820px) {
  .screenback {
    display: block;
  }

  .mainC {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }

  .backabtn {
    left: 3.2rem
  }


}

@media screen and (max-width: 680px) {

  .mainC {
    width: 640px;
  }

  #backicon {
    left: 10px
  }

  .content {
    width: 100%;
  }

}

@media screen and (max-width: 540px) {
  .mainC {
    width: 100%;
    height: 100vh;
    position: relative;
    border-radius: 0px;
  }

  .content {
    margin-top: 0.2rem;
  }

  .backabtn {
    left: 9rem;

  }

}

@media screen and (max-width: 414px) {
  .fE>input {
    width: 290.37px;
    height: 61.17px;
  }

  .fE {
    width: 320.37px;
    height: 55.17px;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .content {
    margin-top: 0.2rem;
    position: absolute;
    top: 6.1rem;


  }

  .content> :nth-child(2) {
    width: 70px;
  }

  .content>h2 {
    font-size: 20px;
  }



  .backabtn {
    left: 12.1rem;
    top: -5rem;
  }

  #sbtn {
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 375px) {
  .backabtn {
    left: 13rem;
    top: -1rem;
  }
}