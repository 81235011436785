/* .filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
} */

.filter{
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  }
  .filter_background{
    background-color: rgba(0, 0, 0, 0.21);
    width:100vw;
    position: absolute;
    left:0%;
    top:0%;
    height:100vh;
  
  }
.filterwhitebg{
  position:absolute;
  right: 0;
  top: 0%;
  z-index:1;
}

.filterbutton>:active{
  transform: scale(0.9);
}

.filtercntnt {
  padding: 0 1.5rem;
}

.filterwhitebg {
  width: 300px;
  height: 708px;
  background: #ffffff;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
}

.filtertext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.filtertext > img {
  width: 20px;
  cursor: pointer;
}
.filtertext > h4 {
  color: #171d1e;
  font-weight: 600;
  font-size: 20px;
}

.firstC {
  display: flex;
  align-items: center;
  gap: 10px;
}

.firstC > input {
  width: 24px;
  height: 24px;
  margin: 5px;
}

.professiondiv {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 3rem;
}

.secondC {
  display: flex;
  align-items: center;
  gap: 10px;
}

.secondC > input {
  width: 24px;
  height: 24px;
  margin: 5px;
}

.dancing {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ratingdiv > .vendorrating {
  width: 48px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ratingdiv {
  display: flex;
  gap: 0.4rem;
}

.ratingdiv > span {
  font-size: 16px;
  font-weight: 500;
  align-self: center;
}

.vendorrating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.vendorrating:active{
  background-color: #007CC0;
}

.byrating {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.byrating > h4 {
  /* font-weight: 500; */
  color: #171d1e;
}

.filterbtns {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

#clear {
  width: 124.58px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}

#applybtn {
  width: 124.58px;
  height: 45px;
  background: #00bfd3;
  border-radius: 6px;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

#applybtn:active,
 #clear:active{
  transform: scale(0.9);
}
.filterdate{
  margin-top:5%;

}
.filterdate>label{
 width:130px;
}
.filterdate>label>span{
  color:#0a0a0a72

}
.filterdate>input{
  font-size: 1rem;
  color: darkgrey;
  border-color: darkgray;
}
@media(max-width:1000px){
  .vmycontainer{
    padding: 0 10px;
  }
}
