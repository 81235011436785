.jobdetails_bids {
  border: 1px solid rgba(0, 0, 0, 0.201);
  margin: 2%;
  padding: 2%;
  width: 45%;
  float: left;
  cursor: pointer;

}


.jobdetailsbid_div {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;

}

.jobdetailsbid_div>img {
  width: 23%;
  height: 16vh;
  border-radius: 50%;
}

.jobdetails_bidsright {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

.jobdetails_miles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.jobdetails_bidinfo {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.jobdetails_biddetails>p {
  color: #040404a3;
  margin: 4%;
}

.jobdetails_biddetails>h4 {
  font-size: 16px;
}

.jobdetailsbid_button>button {
  cursor: pointer;
  width: 46%;
  padding: 2%;
  margin: 5% 2%;
  background-color: #00bfd3;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
}

.jobdetailsbid_button>button:disabled {
  background-color: #d5d3d3;
  color: #636262;
  cursor: not-allowed;
}

.jobdetailsbid_button>button:disabled:hover {
  border: none;
  color: #636262;
  background-color: #d5d3d3;
  cursor: not-allowed;
}

.jobdetailsbid_button>button:hover {
  background-color: white;
  border: 1px solid #00bfd3;
  color: #00bfd3
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.nodata {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.801);
  margin-top: 10%;
  margin-bottom: 10%;
}

.placed {
  position: absolute;
  top: 30%;
  z-index: 1000;
  right: 32%;
  width: 35%;
  padding: 1rem 3rem;
  text-align: center;
  height: 300px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center
}

.cardchoose {
  position: absolute;
  top: 30%;
  z-index: 1000;
  right: 15%;
  width: 60%;
  padding: 1rem;
  text-align: center;
  height: 300px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center
}



.placed_b {
  width: 100vw;

}

.placed_bg {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0%;
  height: 100vh;

}

.placed>button {
  width: 60%;
  padding: 3%;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  cursor: pointer;
}

.placed>button:hover {
  border: 2px solid #00bfd3;
  color: #00bfd3
}

@media(max-width:700px) {
  .jobdetails_bids {
    width: 96%;
  }
}

@media(max-width:500px) {
  .jobdetailsbid_div>img {
    width: 28%;
    height: 12vh
  }

  .jobdetails_bidsright>h2 {
    font-size: 22px;
  }

  .placed {
    width: 70%;
    right: 15%;
    height: 200px
  }
}