
.vendor{
    background-color: #FAFAFA;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vText{
    padding-top: 5rem;
}



.vText>h1{
    text-align: center;
    font-size: 2.3rem;
    color:#091011;
    font-weight: 700;
}

.vText>span{
    color: #0DC3D7;
    display: block;
    text-align: center;
   
}

.mycard{
    background-color: #FFFFFF;
    width: 350px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem;
    text-align: center;
    padding-bottom: 14px;
    box-shadow: 0px 3px 5px rgba(158, 158, 158, 0.37);
    transition: all 0.5s ease-in-out;
}

.mycard>h3{
    color: #091011;
}
.mycard>:nth-child(4){
    width: 110px;
}

.mycard:hover{
    transform: scale(1.1);
}


/* card image */
.mycard>:nth-child(1){
    width: 350px;
}

.mycard>:nth-child(3){
    color: #0DC3D7;
}

.cards{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.btnM{
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.vText>:nth-child(3){
    display: flex;
    justify-content: center;
    padding-bottom: 1.4rem;
  
}

/* media query */

@media screen and (max-width: 768px) {
    .mycard{
        width: 320px;
    }

    .mycard>:nth-child(1){
        width: 320px;
    }

    .vendor{
        margin-top: 3rem;
    }
    
}

@media screen and (max-width: 414px){
    .vText>h1{
        text-align: center;
        font-size: 1.8rem;
    }
}