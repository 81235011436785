.footer {
  background-color: #f1f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  flex-direction: column;
  padding: 5rem;
}

.footer>a> :nth-child(1) {
  width: 110px;
}

.navlinks {
  display: flex;
  gap: 1rem;
}

.navlinks>li {
  transition: all 0.4s ease-in-out;
  list-style: none;
  margin: 0 1.2rem;
}

.navlinks>*:hover {
  transform: scale(1.1);
}

.navlinks>li>a {
  text-decoration: none;
  color: #091011;
  font-weight: 500;
  font-size: 1.2rem;
}

.navlinks>li>a:hover {
  color: #0dc3d7;
  cursor: pointer;
}

.sIcons {
  display: flex;
  gap: 1.8rem;
  margin-top: 2rem;
  cursor: pointer;

}

.sIcons>* {
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.sIcons>*:hover {
  transform: scale(0.9);
}






.sIcons>:nth-child(1) {
  background-color: #4267B2;
  border-radius: 35px;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.sIcons>:nth-child(2) {
  background-color: #dd2a7b;
  border-radius: 35px;
  padding-right: 2px;
  width: 41px;
  align-self: center;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;

}




.copyright {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #091011;
  color: white;
  height: auto;
  padding: 1.1rem;
}


@media screen and (max-width: 912px) {
  .navlinks>li>a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 820px) {
  .navlinks>li>a {
    font-size: 0.9rem;
  }
}


@media screen and (max-width: 768px) {
  .footer>a> :nth-child(1) {
    width: 100px;
  }

  .navlinks>li>a {
    font-size: 0.9rem;
  }

  .navlinks>li {
    margin: 0 1rem;
  }

  .footer {
    padding: 4rem;
  }
}

@media screen and (max-width: 540px) {
  .navlinks>li>a {

    font-size: 9px;
  }

  .footer {
    padding: 1.5rem;
  }

  .footer>a> :nth-child(1) {
    width: 70px;
  }


  .sIcons>* {
    transform: scale(0.6);
  }

  .copyright {
    font-size: 13px;
  }
}

@media screen and (max-width: 414px) {
  .navlinks {
    flex-direction: column;
    align-self: flex-start;
    margin-left: 5px;
  }

  .navlinks>li>a {
    font-size: 15px;
  }


  .sIcons {
    /* flex-direction: column; */
    border-top: 1px solid #0dc3d7;
    padding-top: 15px;
    margin-top: -0.1rem;
  }
}

@media screen and (max-width: 375px) {
  .navlinks {
    margin-left: -0.9rem;
  }
}