.Userprofile {
    border: 1px solid #00bfd3;
    border-radius: 5px;
    width: 96%;
    margin: 2%;
}

.userprofileinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5%;
    gap: 5%
}

.userprofileinfo_img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: rgb(220, 217, 217);
}

.userprofileinfo>h2 {
    font-size: 22px
}

.profilechat {
    display: flex;
    width: 75%;
    justify-content: space-between;
    align-items: center;
}

.profilechat>img {
    width: 45px;
    height: 45px;
    cursor: pointer
}

.Viewuserprofile {
    border-top: 1px solid rgb(3 3 3 / 20%);
    padding: 3%;
    text-align: center;
    color: #00bfd3;
    cursor: pointer;
}

.Couplepostdetails_nav {
    background-color: #007cc0;
    padding: 2%;
    color: white;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 41%;
    position: relative;

}

.Coupledetails_content {
    display: flex;
    flex-direction: column;
}

.Couplepostdetails_details {
    padding: 5%;
    width: 50%;
}

.Couplepostdetails_main {
    width: 100%;
    margin: 2% 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #ecf0f2;
}

/* .div1>h4{
        font-weight: bold;
    } */
.Couplepostdetails_details>p {
    margin-top: 4%;
    margin-bottom: 7%;
    color: #040404a3;

}

.Couplepostdetails_details>h3 {
    font-size: 16px;
}

.Couplepostdetails_img {
    /* margin:5%; */
    display: block;
}

.Couplepostdetails_img>img {
    margin: 10% 2%;
    border-radius: 25px;
    width: 100%;
    height: 80%;
}

.Couplepostdetails_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

}

.Couplepostdetails_text>h2 {
    font-size: 23x;
    margin-bottom: 10px;
}

/* .Couplepostdetails_text>p{
      height: 26px;
      color:white;
      background-color:#00bfd3;
      font-weight: 400;
      font-size: 13px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:7px;
    } */
/* .Couplepostdetails_details>button{
        width: 100%;
        font-size:0.9rem;
        height: 40px;
        background: #00bfd3;
        border-radius: 6px;
        color: white;
        border: none;
        cursor: pointer;
        display: block;
        margin:5% auto;
      
    } */
.Couplepostdetails_list {
    /* width:50%; */
    height: 100vh;
    border-left: 1px solid rgba(132, 131, 131, 0.273);
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    gap: 0%
}

.couplepost_bids {
    border: 1px solid rgba(0, 0, 0, 0.201);
    margin-left: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 2%;
    width: 47%;
    float: left;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;

}

.couplepost_bids>img {
    width: 20%;
    height: 14vh;
    border-radius: 50%;
}

.couplepost_bidsright {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
}

.couplepost_miles {
    display: flex;
    align-items: center;
    gap: 10px;
}

.couplepostdetails_bidinfo {
    display: flex;
    align-items: center;
    gap: 4rem;
}

.couplepost_biddetails>p {
    color: #040404a3;
    margin: 4%;
}

.couplepost_biddetails>h4 {
    font-size: 16px;
}

.couplepost_totalbids>h2 {
    margin-left: 2%
}

.buttonIn>input[type=number] {
    width: 100%;
    height: 3rem;
    border: 2px solid rgba(0, 0, 0, 0.146);
    margin-bottom: 1%;
    color: rgba(0, 0, 0, 0.828);
    font-size: 19px;
    border-radius: 5px;
    padding: 1%;
}

.buttonIn>input[type=number]:disabled {
    cursor: not-allowed;
    background-color: #ececec;
    opacity: 0.5;
}

.couplepost_bidinput {
    margin: 2% 0%;
}

.couplepost_bidinput>p {
    margin-left: 2%;
}

.couplepost_icon {
    border: none;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: #fff;
    cursor: pointer;
}

.couplepost_icon:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.buttonIn {
    width: 96%;
    position: relative;
    margin: 1% 2%;
    display: flex;
}

.couplepostdetails_list {
    border-left: 1px solid rgba(132, 131, 131, 0.273);
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    gap: 0%
}

.couplepostdetails_list>li {
    width: 50%;
    text-align: center;
}

.couplepostdetails_list>li a {
    color: #373d3d;
    text-decoration: none;
    display: block;
    padding-bottom: 6%;
    border-bottom: 1px solid #373d3d;


}

.couplepostdetails_list>li>a.active {
    color: #0dc3dc;
    border-bottom: 1px solid #0dc3dc;
}

.complete_btn {
    background-color: #1EA95F;
    width: 100%;
    font-size: 0.9rem;
    height: 40px;
    border-radius: 6px;
    color: white;
    border: none;
    cursor: pointer;
    display: block;
    margin: 5% auto;

}

.couplepost_modalname {
    font-size: 20px;
    margin: 10px 0;
}

.couplepost_modal {
    font-size: 17px;
    margin: 10px 0;
}

.couplepost_button {
    background: transparent;
    border: none;
    outline: none;
    font-size: 15px;
    color: #57a78c;
    margin: 15px 10px;
    font-weight: 700;
    float: right;
    cursor: pointer;
}

.postdetailsdiv {
    flex-wrap: wrap !important;
}

@media(max-width:650px) {
    .couplepost_bids {
        width: 80%;
        margin: 3% 10%;
    }
}

@media(max-width:500px) {
    .couplepost_bids {
        width: 95%;
        margin: 4% 2%;
        padding: 4% 2%;
    }

    .Couplepostdetails_nav {
        /* height:50px; */
        padding: 5% 2%;
        gap: 25%;
    }

    .couplepost_bids>img {
        width: 25%;
        height: 13vh
    }

    .couplepost_bidsright {
        gap: 5px;
    }

    .couplepost_bidsright>h2 {
        font-size: 20px;
    }

    .Couplepostdetails_content {
        flex-direction: column;
    }

    .Couplepostdetails_img {
        display: none;
    }

    .Couplepostdetails_main {
        margin: 5% 0%;
        background-color: white;
    }

    .Couplepostdetails_details {
        width: 100%;
    }
}