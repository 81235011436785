.leftside>:nth-child(1){
    width: 65%;
    margin-top: 5rem;
}
.leftside>:nth-child(2){
    width: 25%;
    position: relative;
    left: -20%;
    top: 4rem;
}

.services{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.services>.leftside{
    flex: 1;
}

.services>.rightside{
    flex: 1;
}

.profession{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.profession>:nth-child(1){
    background-color: #0dc3d746;
    color: #0DC3D7;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


  /* right h5 */
.rightside>div>h5{
   color: #0DC3D7;
}

/* right h1 */
.rightside>:nth-child(2){
    font-size: 2.5rem;
    color:#091011;
}


.rightside{
    margin-top: 10rem;
}

/* text p */
 .text>:nth-child(2){      
    color: #adbabc;
 }

 /* text h3 */
 .text>:nth-child(1){
    font-weight: 500;
    color:#091011;
    
 }

 .text{
    padding: 1.1rem;
 }

 
 .profession>.text>h3{
    font-size: 1.3rem;
    color: #091011;
}
.profession>.text>p{
    font-size: 15px;
    color:#7D9295;
}


/* meddia query */


@media screen and (max-width: 1280px){
    .leftside>:nth-child(1){
        width: 95%;
       
    }

    .leftside>:nth-child(2){
        width: 35%;
        position: relative;
        left: 65%;
        top: -8.2rem;
    }

    .rightside{
        margin-top: -2rem;
    }

    .services{
        gap: 2.5rem;
    }
}

@media screen and (max-width: 1024px) {
    .leftside>:nth-child(1){
        width: 90%;
       
    }
    .leftside>:nth-child(2){
        width: 35%;
        position: relative;
        left: 60%;
        top: -7rem;
    }

    .rightside>:nth-child(2){
        font-size: 2rem;
                          
    }

    .profession{
        gap: 0.6rem;
    }

    .rightside{
        margin-top: -2rem;
    }

    .text>:nth-child(2){      
       font-size: 14px;
     }
    
     .text{
        padding: 0.8rem;
        
     }

     .services{
        gap: 2.5rem;
    }

    .profession>:nth-child(1){
        transform: scale(0.8);
    }

}

@media screen and (max-width: 912px) {


    .leftside>:nth-child(1){
        width: 100%;
    }

    .leftside>:nth-child(2){
        width: 35%;
        position: relative;
        left: 70%;
        top: -6rem;
    }

    .rightside>:nth-child(2){
       font-size: 1.5rem;
                          
    }

    .rightside>:nth-child(1){
       font-size: 12px;
     }

     .profession>:nth-child(1){
        transform: scale(0.6);
    }

    .profession>.text>h3{
        font-size: 1rem;
    }
    .profession>.text>p{
        font-size: 10px;
    }

   
   .text{
    padding: 0.5rem;
    
 }

 .text>h3{
    font-size: 1.1rem;
 }

  .rightside>#whyus{
    margin-left: 1rem;
  }
}

@media screen and (max-width: 768px) {
    .leftside>:nth-child(1){
        width: 100%;
    }

    .leftside>:nth-child(2){
        width: 35%;
        position: relative;
        left: 70%;
        top: -6rem;
    }

    .rightside>:nth-child(2){
       font-size: 1.5rem;
                          
    }

    .rightside>:nth-child(1){
       font-size: 12px;
     }

     .profession>:nth-child(1){
        transform: scale(0.6);
    }

    .profession>.text>h3{
        font-size: 1rem;
    }
    .profession>.text>p{
        font-size: 10px;
    }

   
   .text{
    padding: 0.5rem;
    
 }

 .text>h3{
    font-size: 1rem;
 }

  .rightside>#whyus{
    margin-left: 1rem;
  }

}

@media screen and (max-width: 540px){
    .profession>.text>h3{
        font-size: 0.9rem;
    }

    .profession>.text>p{
        font-size: 12px;
    }

    .services{
        flex-direction: column 
    }

    

    .leftside>:nth-child(1){
        width: 80%;
    }

    .leftside>:nth-child(2){
        width: 35%;
        position: relative;
        left: 55%;
        top: -7rem;
    }

   
}

@media screen and (max-width: 414px){
    .profession>.text>p{
        font-size: 10px;
    }
}