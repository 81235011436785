.openchat_bids {
  border: 1px solid rgba(0, 0, 0, 0.201);
  margin: 2%;
  padding: 2%;
  width: 45%;
  float: left;
  cursor: pointer;

}

.openchatbid_div {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;

}

.openchatbid_div>img {
  width: 23%;
  height: 23%;
  border-radius: 50%;

}

.openchat_bidsright {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
}

.openchat_miles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.openchat_bidinfo {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.openchat_biddetails>p {
  color: #040404a3;
  margin: 4%;
}

.openchat_biddetails>h4 {
  font-size: 16px;
}

.openchat_button>button {
  width: 100%;
  padding: 5%;
  margin: 5% 2%;
  background-color: #00bfd3;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.openchat_button>button:hover {
  background-color: white;
  border: 1px solid #00bfd3;
  color: #00bfd3
}


@media(max-width:700px) {
  .openchat_bids {
    width: 96%;
  }
}

@media(max-width:500px) {
  .openchatbid_div>img {
    width: 28%;
    height: 12vh
  }

  .openchat_bidsright>h2 {
    font-size: 22px;
  }
}