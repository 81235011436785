.testimonials {
  margin-top: 10rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.review {
  text-align: center;
}

.review> :nth-child(3) {
  display: flex;
  justify-content: center;
}

.review>span {
  color: #0dc3d7;
  font-size: 1.2rem;
  display: block;
}

.review>h1 {
  font-size: 2.7rem;
  padding-bottom: 10px;
  display: inline-block;
  color: #091011;
}

.card1 {
  width: 423px;
  height: 220px;
  background-color: #ffffff;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.08);
  text-align: center;
  margin: 0.9rem;
  border-radius: 10px;
  padding: 0 12px;
}

/* card first image */
.card1> :nth-child(1) {
  width: 70px;
}

/* card rating image */
.card1> :nth-child(4) {
  width: 100px;
}

.tcards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.card1>h3 {
  color: #091011;
  margin-bottom: 5px;
}

.card1>p {
  color: #7d9295;
  margin-bottom: 10px;
}

.arrow {
  display: flex;
  gap: 10px;
}

.arrow>div {
  background-color: transparent;
  border: 1.3px solid #0dc3d7;
  border-radius: 60px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #091011;
}

.arrow>*:hover {
  transform: scale(1.1);
}


.getApp {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  justify-content: center;
  background-color: #fafafa;
  margin-top: 5rem;
  padding: 7rem;
}

.getApp>.Leftside>h1 {
  color: #091011;
}

.Rightside>img {
  width: 500px;
  z-index: 10;
}

.blur1 {
  width: 212px;
  height: 35.17px;
  background: rgba(0, 0, 0, 0.6);
  filter: blur(84px);
  transform: rotate(3.45deg);
  top: -7rem;
  left: 5rem;
  position: relative;
}

.blur {
  width: 212px;
  height: 35.17px;
  background: rgba(0, 0, 0, 0.6);
  filter: blur(84px);
  transform: rotate(3.45deg);
  top: -5rem;
  left: 11rem;
  position: relative;
}

.Leftside>span {
  color: #0dc3d7;
}

.Leftside>p {
  color: #7d9295;
}

.links {
  display: flex;
  gap: 1rem;
}

.Leftside {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

/* media query */

@media screen and (max-width: 820px) {
  .review>h1 {
    font-size: 2.4rem;
  }

  .getApp {
    flex-direction: column;
    gap: 4rem;
  }

  .Rightside>img {
    transform: scale(0.9);
  }

  .Rightside {
    margin-left: 8rem;
  }

  .getApp>h1 {
    font-size: 0.9;
  }

  .blur1 {
    width: 200px;
    height: 35.17px;
  }

  .blur {
    width: 200px;
    height: 35.17px;
  }

  .Rightside {
    margin-left: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .review>h1 {
    font-size: 2.4rem;
  }

  .getApp {
    flex-direction: column;
    gap: 4rem;
  }

  .Rightside>img {
    transform: scale(0.9);
  }

  .Rightside {
    margin-left: 1rem;
  }

  .getApp>h1 {
    font-size: 0.9;
  }

  .blur1 {
    width: 200px;
    height: 35.17px;
  }

  .blur {
    width: 200px;
    height: 35.17px;
  }
}

@media screen and (max-width: 540px) {
  .card1 {
    width: 330px;
    height: auto;
    padding: 8px 12px;
  }

  .getApp {
    padding: 5rem;
    gap: 1rem;
    margin-bottom: -4rem;
  }

  .Leftside>p {
    font-size: 13px;
  }

  .kheniicon2 {
    width: 110px;
  }


  .kheniicon {
    width: 90px;
  }



  .review>h1 {
    font-size: 1.8rem;
  }

  .Rightside {
    margin-left: -0.9rem;
  }

  .Rightside>img {
    transform: scale(0.7);
  }

  .blur1 {
    width: 100px;
    height: 25px;
    top: -8rem;
    left: 8rem;
  }

  .blur {
    width: 100px;
    height: 25px;
    top: -7rem;
    left: 15rem;
  }
}


@media screen and (max-width: 414px) {
  .review>h1 {
    font-size: 1.9rem;
  }

  .Rightside>img {
    width: 400px;
  }

  .Leftside>h1 {
    font-size: 1.3rem;
  }
}